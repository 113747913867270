export const getKeywordTags = value => {
  if (!Array.isArray(value) || value.length === 0) {
    return { existentKeywordTagsIds: [], newKeywordTags: [] };
  }

  return value.reduce(
    (acc, curr) => {
      if (!curr || typeof curr !== 'object') return acc; // Skip null, undefined, or non-object values

      if (curr.id) {
        acc.existentKeywordTagsIds.push(curr.id);
      } else if (typeof curr.keyword === 'string' && curr.keyword.trim()) {
        acc.newKeywordTags.push(curr.keyword);
      }

      return acc;
    },
    { existentKeywordTagsIds: [], newKeywordTags: [] }
  );
};

export const formatKeywordTags = formKeywords => {
  if (formKeywords?.existentKeywordTagsIds && formKeywords?.newKeywordTags) {
    return formKeywords;
  }

  return getKeywordTags(formKeywords);
};
