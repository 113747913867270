export const updateConversationList = ({ conversations, id, updates }) => {
  if (!id) return conversations;

  const conversationIndex = conversations.findIndex(conversation => conversation.id === id);
  if (conversationIndex === -1) return conversations; // No matching conversation, return original

  const updatedConversation = {
    ...conversations[conversationIndex],
    ...updates
  };

  // Update in place without reordering
  return conversations.map((conversation, index) =>
    index === conversationIndex ? updatedConversation : conversation
  );
};

export const formatAttachmentsInResponse = responseMessages => {
  if (!responseMessages) return [];

  return responseMessages.map(item => ({
    ...item,
    attachment: item.attachments?.[0]
      ? { url: item.attachments[0], size: item?.attachmentSize }
      : null
  }));
};

/**
 * Finds the newest and oldest items in an array by date.
 * @param {Array} items - Array of objects with a `date` property.
 * @returns {{ newest: object | null, oldest: object | null }} - An object with `newest` and `oldest` items or `null` if invalid.
 */
export const findExtremeItemsByDate = items => {
  if (!Array.isArray(items) || items.length === 0) {
    return { newest: null, oldest: null };
  }

  const hasInvalidDate = items.some(
    item => !item.date || Number.isNaN(new Date(item.date).getTime())
  );

  if (hasInvalidDate) {
    return { newest: null, oldest: null };
  }

  return items.reduce(
    (extremes, current) => {
      const currentDate = new Date(current.date);
      const newest = currentDate > new Date(extremes.newest.date) ? current : extremes.newest;
      const oldest = currentDate < new Date(extremes.oldest.date) ? current : extremes.oldest;

      return { newest, oldest };
    },
    { newest: items[0], oldest: items[0] }
  );
};

/**
 * The function `sanitizeAndValidatePhone` sanitizes and validates a phone number by removing non-digit
 * characters and ensuring it is between 8 and 15 digits long.
 * @returns The function `sanitizeAndValidatePhone` returns a sanitized and validated phone number. If
 * the input `phoneNumber` is falsy, it returns `null`. Otherwise, it removes all non-digit characters
 * from the input, ensures the length of the cleaned phone number is between 8 and 15 digits, and then
 * returns the validated phone number in the format `+1XXXXXXXXXX` or `+XXXXXXXX
 */
export const sanitizeAndValidatePhone = phoneNumber => {
  if (!phoneNumber) return null;

  // Remove all non-digit characters, including +
  const cleanedPhone = phoneNumber.replace(/\D/g, '');

  // Ensure the phone number is 10-15 digits long
  const isValidPhone = cleanedPhone.length >= 8 && cleanedPhone.length <= 15;

  const validPhone =
    isValidPhone && phoneNumber.startsWith('+') ? `+${cleanedPhone}` : `+1${cleanedPhone}`;

  return isValidPhone ? validPhone : null;
};
