import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import RefreshIcon from '@material-ui/icons/Refresh';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import {
  DEFAULT_MESSAGE_STATUS,
  MESSAGE_FAILURE_STATUSES,
  MESSAGE_STATUS_COPIES
} from 'features/message-center/constants';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { When } from 'UI/components/atoms/When';
import { DateFormats } from 'UI/constants/defaults';
import { localTimeFormatter } from 'UI/utils';

import { MESSAGE_STATUS } from '../chat.constants';

import { AttachmentMessage } from './AttachmentMessage';
import { MessageBubble, MessageContainer, MessageRow, MessageText } from './Message.styled';

const NON_RETRYABLE_HTTP_CODES = [
  HTTPStatusCodes.BadRequest,
  HTTPStatusCodes.Forbidden,
  HTTPStatusCodes.UnprocessableContent
];

const statusMessageRender = {
  [MESSAGE_STATUS.sending]: <CircularProgress size={16} />,
  [MESSAGE_STATUS.success]: <DoneIcon aria-label="Done Icon" color="primary" />,
  [MESSAGE_STATUS.error]: handleClickRetry => (
    <FPIconButton aria-label="Error Icon" onClick={handleClickRetry}>
      <RefreshIcon color="error" />
    </FPIconButton>
  )
};

export const Message = ({
  attachment: attachmentProp,
  attachments,
  date,
  error,
  isOutbound,
  message,
  messageId,
  messageStatus = DEFAULT_MESSAGE_STATUS,
  onClickRetry,
  status
}) => {
  const attachment = attachmentProp ?? attachments?.[0];
  const [showIcon, setShowIcon] = useState(true);

  const localTime = localTimeFormatter(date, DateFormats.SimpleTime);
  const canRetrySend = !NON_RETRYABLE_HTTP_CODES.includes(error?.statusCode ?? '');
  const shouldRenderStatusIcon = showIcon && canRetrySend && statusMessageRender[status];
  const hasErrorStatus = MESSAGE_FAILURE_STATUSES.includes(messageStatus);
  const errorMessage =
    error?.message ??
    (hasErrorStatus ? MESSAGE_STATUS_COPIES[messageStatus] : strings.messageCenter.error.default);

  useEffect(() => {
    const timeOut =
      status === MESSAGE_STATUS.success &&
      setTimeout(() => {
        setShowIcon(false);
      }, 3000);

    return () => {
      timeOut && clearTimeout(timeOut);
    };
  }, [status]);

  return (
    <MessageRow id={messageId} isOutbound={isOutbound}>
      <MessageContainer isOutbound={isOutbound}>
        <When condition={shouldRenderStatusIcon}>
          {typeof statusMessageRender[status] === 'function'
            ? statusMessageRender[status](onClickRetry)
            : statusMessageRender[status]}
        </When>

        <MessageBubble isOutbound={isOutbound}>
          <When condition={attachment && attachment?.url}>
            <AttachmentMessage attachment={attachment} isOutbound={isOutbound} />
          </When>

          <MessageText isOutbound={isOutbound}>{message}</MessageText>

          <MessageText variant="body2" isOutbound={isOutbound} isHourText>
            {localTime === 'Invalid date' ? '' : localTime}
          </MessageText>
        </MessageBubble>
      </MessageContainer>

      <When condition={hasErrorStatus || status === MESSAGE_STATUS.error}>
        <Typography color="error" variant="body2">
          {errorMessage}
        </Typography>
      </When>
    </MessageRow>
  );
};
