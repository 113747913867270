import React from 'react';
import Box from '@material-ui/core/Box';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import strings from 'strings';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import { Endpoints } from 'UI/constants/endpoints';
import { ContactRole, ContactRoleStrings } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { OptionRenderers } from 'UI/utils/renderers';

import { changeTypeAheadParams } from './SearchInput.utils';

const REQUIRED_LENGTH_FOR_KEYWORD = 4;
const REQUIRED_LENGTH_FOR_PHONE_NUMBER = 6;

export const validateTypeAheadKeyword = keyword => {
  const trimmedKeyword = keyword.trim();
  const includesLetters = trimmedKeyword.match(/[a-zA-Z]/);

  if (includesLetters) {
    return trimmedKeyword.length >= REQUIRED_LENGTH_FOR_KEYWORD;
  }

  if (trimmedKeyword.startsWith('+')) {
    return trimmedKeyword.length >= REQUIRED_LENGTH_FOR_PHONE_NUMBER + 2;
  }

  return trimmedKeyword.length >= REQUIRED_LENGTH_FOR_PHONE_NUMBER;
};

export const SearchInput = ({ onSelectValue, contact }) => {
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();
  const canSendToUnsavedContact = checkIfFeatureFlagEnabled(
    FeatureFlags.MessageCenterSendToUnsavedContact
  );

  const handleSelect = (name, value) => {
    onSelectValue(value);
  };

  const { globalSearchPerson } = OptionRenderers;

  const optionParams = {
    fallback: null,
    chipPropsBuilder: null,
    showOtherEmail: true
  };

  const handleRenderOption = option => {
    const createSubtitle = fromOption => {
      if (fromOption.role_id === ContactRole.Candidate) {
        return `${fromOption.title} / ${fromOption.location}`;
      }
      return `${fromOption.current_company} / ${fromOption.title}`;
    };

    const newOption = {
      ...option,
      role: ContactRoleStrings[option?.role_id],
      subtitle: option?.createButton ? '' : createSubtitle(option)
    };

    return globalSearchPerson(
      'full_name',
      optionParams.fallback,
      optionParams.chipPropsBuilder,
      optionParams.showOtherEmail
    )(newOption);
  };

  return (
    <AutocompleteSelect
      apiVersion={2}
      changeTypeAheadParams={changeTypeAheadParams}
      displayKey="full_name"
      getOptionLabel={option => option.keyword ?? option.full_name}
      groupBy={option => option?.role_id}
      label={strings.messageCenter.newConversation.searchInputLabel}
      onSelect={handleSelect}
      renderOption={handleRenderOption}
      selectedValue={contact}
      typeahead
      typeaheadLimit={50}
      typeaheadParams={{ perPage: 50 }}
      url={Endpoints.Names}
      validateTypeAheadKeyword={keyword => validateTypeAheadKeyword(keyword)}
      renderGroup={params => {
        const finalGroup = ContactRoleStrings[params.group];
        return (
          <div key={params?.key}>
            <Box paddingX={1} fontWeight={600}>
              {finalGroup}
            </Box>
            {params.children}
          </div>
        );
      }}
      createButton={
        canSendToUnsavedContact
          ? {
              text: strings.messageCenter.newConversation.sendTo,
              concatKeyword: true,
              internalOptionsFiltering: true
            }
          : undefined
      }
    />
  );
};
