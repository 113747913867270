import strings from 'strings';
import { EntityType } from 'UI/constants/entityTypes';
import { idOptionSelected } from 'UI/utils';
import { OptionRenderers } from 'UI/utils/renderers';

export const FORM_FIELDS_MAP = {
  Date: {
    key: 'date'
  },
  Time: {
    key: 'time'
  },
  Position: {
    key: 'position'
  },
  ReachToEntity: {
    key: 'reachTo'
  },
  ReachToName: {
    key: 'reachToName'
  },
  Comments: {
    key: 'comments'
  }
};

export const REACH_OUT_AUTOCOMPLETE_PROPS_BY_ENTITY = {
  searchProject: {
    placeholder: strings.dailyPlan.drawer.form.placeholders.selectSearchProject,
    url: '/search-projects',
    typeaheadParams: { limit: 20, onlyMine: false },
    getOptionLabel: option =>
      !!option.name && !!option.total_items ? `${option.name} (${option.total_items})` : '',
    renderOption: OptionRenderers.searchProjects,
    getOptionSelected: idOptionSelected
  },
  candidate: {
    placeholder: strings.dailyPlan.drawer.form.placeholders.selectCandidate,
    url: '/search',
    typeaheadParams: { entityType: EntityType.Candidate },
    renderOption: OptionRenderers.globalSearchPerson('title')
  }
};

export const REACH_OUT_TO_RADIO_OPTIONS = [
  {
    id: 1,
    value: 'candidate',
    label: strings.dailyPlan.drawer.form.labels.candidate
  },
  {
    id: 2,
    value: 'searchProject',
    label: strings.dailyPlan.drawer.form.labels.searchProject
  }
];
