import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { FeatureFlags } from 'UI/constants/featureFlags';

export const useContactProfileFeatureFlags = () => {
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  return {
    isEnabledAddAsACandidate: checkIfFeatureFlagEnabled(FeatureFlags.NewCandidateFromContact),
    isChangeLogsTabEnabled: checkIfFeatureFlagEnabled(FeatureFlags.ChangeLogContact)
  };
};
