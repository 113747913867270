// @flow
import React, { useMemo, useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { useChatContext } from 'features/message-center/components/messageChat/ChatDrawerProvider';
import { extractObjectFromDataTable } from 'hooks/datatable';
import { Permissions, userHasPermission } from 'services/Authorization';
import { getPhonesWithId, PHONE_TYPE_MAP } from 'UI/components/organisms/PhoneNumbersForm/utils';
import { RingCentralOriginActions } from 'UI/constants/defaults';
import { ContactRole, ContactRoleStrings, EntityType } from 'UI/constants/entityTypes';
import { SvgArrowDown } from 'UI/res';

import PhoneActionRow from './PhoneActionRow';
import PhoneItem from './PhoneItem';
import { useStyles } from './styles';

type PhoneNumbersDropdownProps = {
  contactValues: Object,
  onPhoneClick: () => void,
  data: Object,
  phonesKey: string,
  phoneKey: string
};

const getRecipientByTableData = ({ data, columns }) => {
  const { rowData } = data;
  const item = extractObjectFromDataTable(
    columns,
    ['id', 'full_name', 'role_id', 'roleId', 'item_search_project_title'],
    rowData
  );
  const id = item?.id ?? item?.full_name?.id ?? item?.name?.id;
  const name = item?.full_name?.full_name ?? item?.name?.name;
  const roleId =
    item?.role_id ??
    item?.roleId ??
    ContactRole[item?.item_search_project_title?.replace(/\s+/g, '')];
  const role = ContactRoleStrings[roleId] ? ContactRoleStrings[roleId].replace(/\s+/g, '') : null;
  return {
    name,
    entityId: id,
    entityType: EntityType[role]
  };
};

const PhoneNumbersDropdown = ({
  columns,
  contactValues,
  data,
  onPhoneClick,
  phonesKey = 'phones',
  phoneKey = 'phone',
  entityType
}: PhoneNumbersDropdownProps) => {
  const phones = contactValues[phonesKey] || [];
  const phone = contactValues[phoneKey] || null;
  const minPhonesToDisplayDropdown = phonesKey === 'phones' ? 1 : 0;
  const canUseTextel = userHasPermission(Permissions.Textel.CanUseModule);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { openDrawer } = useChatContext();

  const uniquePhonesWithIds = useMemo(() => getPhonesWithId(phones), [phones]);

  const mainPhone =
    uniquePhonesWithIds.length > 0 ? uniquePhonesWithIds[0] : { phone, use: null, type_id: null };

  const hasMultiplePhones = uniquePhonesWithIds.length > minPhonesToDisplayDropdown;

  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleOnPhoneClick = phoneItem => {
    onPhoneClick({
      rowData: [...data?.rowData, phoneItem],
      origin: RingCentralOriginActions.MultiplePhonesDropdown
    });

    const recipient = Array.isArray(columns) && getRecipientByTableData({ data, columns });

    canUseTextel &&
      recipient &&
      recipient?.entityId &&
      openDrawer({
        recipient: {
          ...recipient,
          entityType: recipient?.entityType ?? entityType,
          phone: phoneItem?.phone,
          countryCode: phoneItem?.country_code
        }
      });

    handleClose();
  };

  if (!mainPhone?.phone) return null;

  return (
    <div>
      <div className={classes.mainPhone}>
        <PhoneItem
          phone={mainPhone.phone}
          type={PHONE_TYPE_MAP[mainPhone?.type_id]?.title}
          use={mainPhone?.use}
          countryCode={mainPhone?.country_code}
        />
        {hasMultiplePhones && (
          <>
            <IconButton aria-controls="phones-menu" aria-haspopup="true" onClick={handleClick}>
              <SvgArrowDown size={8} />
            </IconButton>
            <Menu
              id="phones-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {uniquePhonesWithIds.map(item => (
                <MenuItem key={item.id}>
                  <PhoneActionRow
                    phone={item?.phone}
                    type={PHONE_TYPE_MAP[item?.type_id]}
                    isDefault={item?.is_default}
                    use={item?.use}
                    countryCode={item?.country_code}
                    onPhoneClick={() => handleOnPhoneClick(item)}
                  />
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </div>
    </div>
  );
};

export default PhoneNumbersDropdown;
