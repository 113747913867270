import { makeStyles } from '@material-ui/core';
import { colors } from 'UI/res';

export const useStyles = makeStyles(theme => ({
  alertContainer: {
    margin: theme.spacing(2.5, 0, 0, 3)
  },
  alert: {
    width: 'fit-content',
    borderRadius: 25,
    padding: theme.spacing('auto', 3),
    color: colors.completeBlack
  },
  userFilter: {
    margin: theme.spacing(3, 0, 3, 3),
    width: 350
  }
}));
