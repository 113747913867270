import React from 'react';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';
import { FPCard } from 'UI/components/atoms/FPCard';
import FPChip from 'UI/components/atoms/FPChip';

import { TEXT_COLOR, useStyles } from './styles';

export const KeywordTags = ({ keywordTags = [], loading }) => {
  const classes = useStyles();

  const hasKeywordTags = Array.isArray(keywordTags) && keywordTags?.length > 0;
  const showKeywordTags = !loading && hasKeywordTags;

  return showKeywordTags ? (
    <div className={classes.container}>
      <FPCard>
        <div className={classes.content}>
          <Typography variant="overline">
            {strings.inventoryProfiles.common.keywords.title}:
          </Typography>

          {keywordTags.map(({ id, display_text: displayText }) => (
            <FPChip key={id} label={displayText} size="small" textColor={TEXT_COLOR} />
          ))}
        </div>
      </FPCard>
    </div>
  ) : null;
};
