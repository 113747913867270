// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { showAlert } from 'actions/app';
import clsx from 'clsx';
import { THEME as theme } from 'GlobalStyles';
import useDebounce from 'hooks/debounce';
import queryString from 'query-string';
import API, { SECONDARY_API_CLIENT } from 'services/API';
import ColorIndicator from 'UI/components/atoms/ColorIndicator';
import FPIcon from 'UI/components/atoms/FPIcon';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { SvgAdd, SvgGeneralUpdate } from 'UI/res';
import { getErrorMessage, nestTernary } from 'UI/utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

import type { AutocompleteSelectProps } from './autocompleteSelect.types';
import {
  statusOption,
  useAutocompleteStyles,
  useAutocompleteStylesDisabled,
  useChipStyles,
  useChipStylesDisabled,
  useCreateButtonStyles
} from './styles';

const API_INSTANCES = {
  default: API,
  secondary: SECONDARY_API_CLIENT
};

const AutocompleteSelect = ({
  api = 'default',
  apiVersion,
  autocomplete,
  canRefresh,
  changeTypeAheadParams,
  children,
  createButton,
  customClasses,
  defaultOptions,
  disabledItemsFocusable = false,
  displayKey,
  endAdornment,
  error,
  errorText,
  fetching,
  getDataOnFocus,
  getOptionLabel,
  getOptionSelected,
  groupBy,
  label,
  multiple,
  name,
  noMargin,
  onCreateButton,
  onKeyDown,
  onOptionsLoaded,
  onSelect,
  onTypeaheadClose,
  placeholder,
  placeholderAsLabel,
  prependOptions,
  selectedValue,
  startAdornment,
  textFieldProps,
  typeahead,
  typeaheadLimit,
  typeaheadParams,
  url,
  validateTypeAheadKeyword,
  ...rest
}: AutocompleteSelectProps) => {
  const apiInstance = API_INSTANCES[api] || API_INSTANCES.default;
  const history = useHistory();
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [keyword, setKeyword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const debouncedKeyword = useDebounce(keyword, 250);
  const classes = useAutocompleteStyles({ noMargin });
  const chipClasses = useChipStyles();
  const createBtnClasses = useCreateButtonStyles();
  const chipClassesDisabled = useChipStylesDisabled();
  const classesDisabled = useAutocompleteStylesDisabled();

  const handleChange = useCallback(
    (event, value, reason) => {
      onSelect && onSelect(name, value, reason);
    },
    [name, onSelect]
  );

  const handleOptionsLoaded = useCallback(
    newOptions => {
      onOptionsLoaded && onOptionsLoaded(newOptions);
    },
    [onOptionsLoaded]
  );

  const handleKeydown = event => {
    onKeyDown && onKeyDown(event);
    if (!typeahead && !createButton?.internalOptionsFiltering) {
      return;
    }
    setKeyword(event.target.value.trim());
  };

  const handleTypeaheadClose = (event, reason) => {
    onTypeaheadClose && onTypeaheadClose(event, reason);
    setKeyword('');
    if (createButton?.internalOptionsFiltering) setOptions(options);
    if (typeahead) setOptions([]);
  };

  const handleFocus = async () => {
    if (getDataOnFocus && typeahead) {
      setLoading(true);
      const queryParams = queryString.stringify(typeaheadParams, { arrayFormat: 'comma' });
      const response = await apiInstance.get(`${url}?${queryParams}`, { apiVersion });
      response.data.data ? setOptions(response.data.data) : setOptions(response.data);
      setLoading(false);
    }
  };

  const defaultOptionSelectedFn = (option, value) => option[displayKey] === value[displayKey];
  const defaultOptionLabelFn = option => option[displayKey] || '';

  const typeaheadProps =
    typeahead || createButton?.internalOptionsFiltering
      ? {
          forcePopupIcon: false,
          onClose: handleTypeaheadClose
        }
      : null;

  const optionsWithButton = (showOptions, optns) => {
    const filterOptions =
      createButton.internalOptionsFiltering && keyword
        ? optns.filter(opt => opt[displayKey].toLowerCase().includes(keyword.toLowerCase()))
        : optns;

    const {
      Component,
      concatKeyword,
      key,
      location,
      text,
      url: buttonUrl,
      openInNewTab
    } = createButton;

    const goToPage = () => {
      const customUrl = queryString.stringifyUrl({
        url: buttonUrl,
        query: { name: keyword }
      });

      if (openInNewTab) {
        window.open(customUrl, '_blank');
      } else {
        history.push(customUrl, {
          prev: location?.pathname
        });
      }
    };

    const renderAction = buttonUrl && location ? goToPage : onCreateButton;

    const loadedComponent = Component ? (
      <Component keyword={keyword} />
    ) : (
      <ListItem
        component="div"
        className={clsx(
          createBtnClasses.createButton,
          showOptions && createBtnClasses.createButtonDivider
        )}
        onClick={renderAction}
      >
        <p className={createBtnClasses.createButtonText}>
          <i className={createBtnClasses.createButtonIcon}>
            <FPIcon component={SvgAdd} color={theme.palette.primary.main} family="mini" size={12} />
          </i>
          {concatKeyword ? `${text}: ${keyword}` : text}
        </p>
      </ListItem>
    );

    const button = { createButton: true, [key || displayKey]: loadedComponent, keyword };
    const validButton = validateTypeAheadKeyword
      ? nestTernary(validateTypeAheadKeyword(keyword), [button], [])
      : [button];

    return showOptions ? [...validButton, ...filterOptions] : validButton;
  };

  const filterOptions = createButton
    ? optns => {
        if (!!keyword && (optns.length === 0 || createButton.showAlways) && !loading)
          return optionsWithButton(createButton.showAlways && optns.length > 0, optns);
        if (!typeahead)
          return optionsWithButton(createButton.showAlways || optns.length > 0, optns);
        return optns;
      }
    : nestTernary(typeahead, optns => optns, undefined);

  const getOptions = useCallback(async () => {
    if (typeahead) return;

    if (!url) {
      setOptions([]);
      return;
    }

    setLoading(true);
    setHasError(false);
    await apiInstance
      .get(url, { apiVersion })
      .then(response => {
        if (response.status === 200) {
          setOptions(prependOptions ? [...prependOptions, ...response.data] : response.data);
          handleOptionsLoaded(response.data);
        } else {
          setHasError(true);
        }
      })
      .catch(err => {
        setHasError(true);
        dispatch(
          showAlert({
            severity: 'error',
            title: 'Error',
            body: getErrorMessage(err)
          })
        );
      });
    setLoading(false);
  }, [typeahead, url, apiVersion, prependOptions, handleOptionsLoaded, dispatch, apiInstance]);

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  useDeepCompareEffect(() => {
    const search = async () => {
      if (!typeahead || !url) return;

      if (!debouncedKeyword) {
        setOptions([]);
        return;
      }

      if (validateTypeAheadKeyword && !validateTypeAheadKeyword(debouncedKeyword)) {
        setOptions([]);
        return;
      }

      const finalTypeAheadParams = {
        keyword: debouncedKeyword,
        limit: typeaheadLimit,
        ...typeaheadParams
      };

      const queryParams = queryString.stringify(
        changeTypeAheadParams
          ? changeTypeAheadParams({
              value: debouncedKeyword,
              typeAheadParams: finalTypeAheadParams
            })
          : finalTypeAheadParams,
        { arrayFormat: 'comma' }
      );

      setLoading(true);
      setHasError(false);

      await apiInstance
        .get(`${url}?${queryParams}`, { apiVersion })
        .then(response => {
          // TODO: fix from server the reponse:
          if (response.data.data) {
            setOptions(response.data.data);
          } else {
            setOptions(response.data);
          }
        })
        .catch(err => {
          dispatch(
            showAlert({
              severity: 'error',
              title: 'Error',
              body: getErrorMessage(err)
            })
          );
        });
      setLoading(false);
    };
    search();
  }, [
    debouncedKeyword,
    dispatch,
    typeahead,
    typeaheadLimit,
    typeaheadParams,
    url,
    validateTypeAheadKeyword
  ]);

  const handleRetry = async () => {
    await getOptions();
  };

  const chipProps = disabledItemsFocusable
    ? { onDelete: null, classes: chipClassesDisabled }
    : { classes: chipClasses };

  const defaultClasses = disabledItemsFocusable ? classesDisabled : classes;

  return (
    <Autocomplete
      noOptionsText="Type to find something"
      multiple={!!multiple}
      ChipProps={{ ...chipProps }}
      getOptionSelected={getOptionSelected || defaultOptionSelectedFn}
      getOptionLabel={getOptionLabel || defaultOptionLabelFn}
      options={defaultOptions || options}
      loading={loading || fetching}
      groupBy={groupBy}
      onChange={handleChange}
      onFocus={handleFocus}
      value={selectedValue}
      loadingText=""
      classes={{ ...defaultClasses, ...customClasses }}
      aria-label={label || (placeholderAsLabel ? placeholder : null)}
      {...typeaheadProps}
      renderInput={params => {
        const hidePlaceholderIfMultiple = multiple && params.InputProps.startAdornment;

        return (
          <>
            <TextField
              {...params}
              label={label || (placeholderAsLabel ? placeholder : null)}
              placeholder={hidePlaceholderIfMultiple ? null : placeholder}
              variant="outlined"
              fullWidth
              name={name}
              error={error}
              helperText={errorText}
              onChange={handleKeydown}
              inputProps={{
                ...params.inputProps,
                autoComplete: autocomplete // to avoid default browser autofill feature
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                ...params.InputProps,
                // workaround for https://github.com/mui-org/material-ui/issues/19479
                startAdornment:
                  selectedValue && startAdornment ? (
                    <>
                      {selectedValue && startAdornment}
                      {params.InputProps.startAdornment}
                    </>
                  ) : (
                    params.InputProps.startAdornment
                  ),
                endAdornment: (
                  <>
                    {loading || fetching ? (
                      <CircularProgress className={classes.loading} color="inherit" size={20} />
                    ) : null}
                    {!disabledItemsFocusable && params.InputProps.endAdornment}
                    {endAdornment}
                  </>
                )
              }}
              {...textFieldProps}
            />
            {children}
            {(hasError || (!loading && canRefresh)) && (
              <FPIconButton
                size="sm"
                onClick={handleRetry}
                className={createBtnClasses.retryButton}
                tooltipProps={{ title: !hasError && canRefresh ? 'Refresh' : 'Retry' }}
              >
                <FPIcon component={SvgGeneralUpdate} size={16} />
              </FPIconButton>
            )}
          </>
        );
      }}
      filterOptions={filterOptions}
      {...rest}
    />
  );
};

AutocompleteSelect.defaultProps = {
  apiVersion: 1,
  autocomplete: 'off',
  createButton: undefined,
  customClasses: {},
  prependOptions: null,
  defaultOptions: null,
  disabledItemsFocusable: false,
  displayKey: 'title',
  endAdornment: undefined,
  error: false,
  errorText: '',
  fetching: false,
  getDataOnFocus: false,
  getOptionLabel: undefined,
  getOptionSelected: undefined,
  groupBy: undefined,
  label: null,
  multiple: false,
  noMargin: false,
  onCreateButton: undefined,
  onKeyDown: undefined,
  onOptionsLoaded: undefined,
  onSelect: undefined,
  onTypeaheadClose: undefined,
  placeholder: '',
  placeholderAsLabel: true,
  selectedValue: null,
  startAdornment: undefined,
  textFieldProps: undefined,
  typeahead: false,
  typeaheadLimit: 15,
  typeaheadParams: {}
};

export default AutocompleteSelect;

const renderStatusIndicator = (color: string) => {
  return <ColorIndicator color={color} size={10} onClick={() => {}} />;
};

export const statusRenderOption = (option: any) => (
  <>
    {renderStatusIndicator(option.style_class_name || option.style || option.color)}
    <Typography noWrap style={statusOption}>
      {option.title}
    </Typography>
  </>
);

export const statusStartAdornment = (color: string) => (
  <Box ml={1}>{renderStatusIndicator(color)}</Box>
);
