// @flow
import React from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import { globalStyles } from 'GlobalStyles';
import type { TitleLabelProps } from 'types/app';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { SvgArrowLeft } from 'UI/res/icons/filled';

import Text from '../Text';

import { styles } from './styles';

const TitleLabel = ({
  actionProps = undefined,
  backNavigation = false,
  backNavigationProps = undefined,
  classes,
  customClass = '',
  customStyle = {},
  DetailsComponent = undefined,
  fontSize = 32,
  gridLoadingProps = undefined,
  loading = false,
  mode = 'goBack',
  onCustomBackClick = () => {},
  subtitleProps = undefined,
  subtitle = '',
  text,
  ...rest
}: TitleLabelProps) => {
  const history = useHistory();

  const handleGoBackButton = () => history.goBack();

  const clickType = mode === 'goBack' ? handleGoBackButton : onCustomBackClick;

  return loading ? (
    <Grid container>
      <Grid item md={4} sm={6} {...gridLoadingProps}>
        <Skeleton style={globalStyles.skeletonItem} variant="rect" role="progressbar" />
      </Grid>
    </Grid>
  ) : (
    <div className={classes.mainContainer}>
      {backNavigation && (
        <FPIconButton
          onClick={clickType}
          iconMode="custom"
          className={classes.iconButton}
          icon={SvgArrowLeft}
          family="mini"
          tooltipProps={{ title: 'Back' }}
          {...backNavigationProps}
        />
      )}
      <div className={clsx(classes.titleContainer, customClass)}>
        <div>
          <div className={classes.mainTitleContainer}>
            <Text
              variant="h1"
              className={classes.mainText}
              style={{ ...customStyle, fontSize }}
              text={text}
              {...rest}
            />
            {actionProps && (
              <div className={classes.actionButtonContainer}>
                <FPIconButton {...actionProps} />
              </div>
            )}
          </div>
          {subtitle && (
            <Typography variant="h2" component="div" {...subtitleProps}>
              {subtitle}
            </Typography>
          )}
        </div>
        {DetailsComponent && (
          <div className={classes.detailsContainer}>
            <DetailsComponent />
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(TitleLabel);
