// @flow
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { UserProviderContext } from 'providers/UserProvider';
import { userHasRole, userHasRoles } from 'services/Authorization';
import strings from 'strings';
import AutocompleteSelectConnected from 'UI/components/molecules/AutocompleteSelect';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { Roles } from 'UI/constants/roles';
import { OptionRenderers } from 'UI/utils/renderers';

import { formNames, SendoutRolesFullPermissions } from '../utils';

import {
  addFullName,
  getAvailableRecruitersAndCollabs,
  getAvailableRecruitersAndCollabsFromCoach,
  isUserOrCoachPresent,
  setRecruiterSchema
} from './Utils/index';

interface SendoutAccountableProps {
  sendoutData: any;
  profile: any;
  onAccountableChange: () => void;
  disabled: Boolean;
}
const SendoutAccountableSection = ({
  sendoutData,
  profile,
  onAccountableChange,
  disabled
}: SendoutAccountableProps) => {
  const { setValue, watch, errors } = useFormContext();
  const accountableValue = watch(formNames.jobOrderAccountable.name);

  const { checkIfFeatureFlagEnabled } = useFeatureFlags();
  const isAssitantRegionalDirectorsOnBehalfSendoutCreation = checkIfFeatureFlagEnabled(
    FeatureFlags.AssitantRegionalDirectorsOnBehalfSendoutCreation
  );

  const [currentUser] = useContext(UserProviderContext);

  const mainSendoutAccountable = sendoutData?.jobOrderAccountable || null;
  const mainJobOrderRecruiter = profile ? { ...profile?.recruiter, coach: profile?.coach } : null;

  const sendoutAccountable = sendoutData?.jobOrderAccountable;

  /* The `getMainRecruiter` function is a memoized callback function that takes an array of
  `recruiters` as an argument and returns the `id` of the main recruiter. */
  const getMainRecruiter = useCallback(
    recruiters => {
      const mainRecuiter = recruiters.find(recruiter => currentUser?.id === recruiter?.id);
      return mainRecuiter?.recruiter_to_collaborate_id || currentUser?.id;
    },
    [currentUser]
  );

  const getAvailableRecruitersAndCollab = useCallback(
    recruiters => {
      const mainRecuiterId = getMainRecruiter(recruiters);
      const availableRecruiters = recruiters.filter(
        recruiter =>
          recruiter?.id === mainRecuiterId ||
          recruiter?.recruiter_to_collaborate_id === mainRecuiterId
      );
      if (isEmpty(availableRecruiters)) return [mainSendoutAccountable ?? mainJobOrderRecruiter];

      const allRecruiters = mainSendoutAccountable
        ? uniqBy([...availableRecruiters, mainSendoutAccountable], 'id')
        : availableRecruiters;

      return allRecruiters;
    },
    [getMainRecruiter, mainSendoutAccountable, mainJobOrderRecruiter]
  );

  const getAvailableRecruitersAndCollabsByCoach = useCallback(
    recruiters => {
      const recruitersOfCoachTeam = recruiters.filter(({ coach }) => coach?.id === currentUser?.id);

      if (isEmpty(recruitersOfCoachTeam)) {
        const isCollaborator = recruiters.some(recruiter => recruiter?.id === currentUser?.id);
        if (isCollaborator) return recruiters;
        return mainSendoutAccountable ? [mainSendoutAccountable] : recruiters;
      }

      const onlyHasOneRecruiter = recruitersOfCoachTeam.length === 1;
      if (onlyHasOneRecruiter) {
        return mainSendoutAccountable ? recruiters : recruitersOfCoachTeam;
      }

      const collabsOfRecruiters = recruiters.filter(each =>
        recruitersOfCoachTeam.some(recruiter => recruiter.id === each.recruiter_to_collaborate_id)
      );

      const collabsOfCoachTeam = collabsOfRecruiters.filter(
        ({ coach }) => coach?.id === currentUser?.id
      );

      if (isEmpty(collabsOfCoachTeam)) {
        return mainSendoutAccountable
          ? uniqBy([...recruitersOfCoachTeam, mainSendoutAccountable], 'id')
          : recruitersOfCoachTeam;
      }
      return uniqBy([...recruitersOfCoachTeam, ...collabsOfCoachTeam], 'id');
    },
    [currentUser, mainSendoutAccountable]
  );

  const allAccountables = useMemo(() => {
    const { additionalRecruiters } = profile || [];
    const accountables = additionalRecruiters
      ? [...additionalRecruiters.map(setRecruiterSchema), mainJobOrderRecruiter]
      : [mainJobOrderRecruiter];

    if (sendoutAccountable && sendoutAccountable?.id !== mainJobOrderRecruiter?.id) {
      accountables.push(sendoutAccountable);
    }

    const uniqueAccountables = uniqBy(accountables, 'id');

    const hasFullPermissions = userHasRoles(SendoutRolesFullPermissions);
    if (hasFullPermissions) return uniqueAccountables;

    const isUserCoach = userHasRole(Roles.Coach);
    if (isAssitantRegionalDirectorsOnBehalfSendoutCreation) {
      const isAssistantRegionalDirectorByCoach =
        userHasRole(Roles.AssistantRegionalDirector) &&
        mainJobOrderRecruiter.coach?.id === currentUser?.teamRelation?.coachId;

      if (isUserCoach || isAssistantRegionalDirectorByCoach) {
        return getAvailableRecruitersAndCollabsFromCoach(
          uniqueAccountables,
          mainSendoutAccountable,
          currentUser
        );
      }
      return getAvailableRecruitersAndCollabs(
        uniqueAccountables,
        mainSendoutAccountable,
        mainJobOrderRecruiter,
        currentUser
      );
    }

    if (isUserCoach) {
      return getAvailableRecruitersAndCollabsByCoach(uniqueAccountables);
    }

    return getAvailableRecruitersAndCollab(uniqueAccountables);
  }, [
    profile,
    mainJobOrderRecruiter,
    sendoutAccountable,
    isAssitantRegionalDirectorsOnBehalfSendoutCreation,
    getAvailableRecruitersAndCollab,
    currentUser,
    mainSendoutAccountable,
    getAvailableRecruitersAndCollabsByCoach
  ]);

  const handleChangeAccountable = (e, value) => {
    setValue(formNames.jobOrderAccountable.name, value);
    onAccountableChange && onAccountableChange(value);
  };

  const areRecruitersFromCoach = isAssitantRegionalDirectorsOnBehalfSendoutCreation
    ? isUserOrCoachPresent(allAccountables, currentUser)
    : allAccountables.some(({ coach }) => coach?.id === currentUser?.id);

  const isSendoutFromTeam = accountableValue
    ? allAccountables.some(accountable => accountable?.id === accountableValue?.id)
    : true;
  const willBeDisabled = allAccountables.length === 1;
  const accountableSelectorDisabled =
    willBeDisabled || (!isSendoutFromTeam && !areRecruitersFromCoach) || disabled;

  useEffect(() => {
    if (!isSendoutFromTeam) setValue(formNames.jobOrderAccountable.name, null);
    if (!isSendoutFromTeam && !areRecruitersFromCoach)
      setValue(formNames.jobOrderAccountable.name, mainSendoutAccountable);
  }, [setValue, mainSendoutAccountable, areRecruitersFromCoach, isSendoutFromTeam]);

  const defaultValueRecruiter = addFullName(
    isSendoutFromTeam ? accountableValue : mainSendoutAccountable
  );

  return (
    <NumberedForm.Container>
      <Typography variant="h6">{strings.sendouts.details.sendoutOnBehalf}</Typography>
      <div className="grid-2x">
        <AutocompleteSelectConnected
          id="autocomplete-accountable-recruiter"
          disabled={accountableSelectorDisabled}
          name={formNames.jobOrderAccountable.name}
          label="Accountable Recruiter"
          placeholder="Select you accountable recruiter *"
          selectedValue={defaultValueRecruiter}
          options={allAccountables.map(addFullName)}
          displayKey="full_name"
          error={!!errors[formNames.jobOrderAccountable.name]}
          errorText={errors[formNames.jobOrderAccountable.name]?.message}
          onChange={handleChangeAccountable}
          renderOption={OptionRenderers.recruiterWithInitials}
        />
      </div>
    </NumberedForm.Container>
  );
};

export default SendoutAccountableSection;
