import React, { useState } from 'react';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import strings from 'strings';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';

import { findUniqueKeywords } from './KeywordPicker.utils';

const AUTOCOMPLETE_OPTION_REASONS = { createOption: 'create-option' };

export const KeywordPicker = ({
  allowNewItems = true,
  displayTextKey,
  error = false,
  errorText,
  endpoint,
  name,
  placeholder,
  selectedValue = [],
  typeaheadLimit = 25,
  onSelect,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('');
  const filter = createFilterOptions();

  const addCustomOption = value => {
    if (!value) return;

    const newValues = [
      ...selectedValue,
      {
        keyword: value.toLocaleLowerCase(),
        [displayTextKey ?? 'value']: value
      }
    ];

    const uniqueValues = findUniqueKeywords(newValues, 'keyword');
    onSelect && onSelect(name, uniqueValues);
  };

  const handleSelect = (_, values, reason) => {
    const newValues = [...values];
    const lastValue = newValues.pop();
    if (reason === AUTOCOMPLETE_OPTION_REASONS.createOption && allowNewItems) {
      lastValue && typeof lastValue === 'string' && addCustomOption(lastValue.trim());
      return;
    }

    const match = lastValue && lastValue[displayTextKey ?? 'value'].match(/^Add "(.*)"$/);
    if (match) {
      const actualValue = match[1];
      typeof actualValue === 'string' && addCustomOption(actualValue.trim());
      return;
    }

    const uniqueValues = findUniqueKeywords(values, 'keyword');
    onSelect && onSelect(name, uniqueValues);
  };

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  const handleClose = () => {
    setInputValue('');
  };

  const filterOptionsWithAddButton = (options, params) => {
    const filtered = filter(options, params);
    if (filtered.length === 0 && params.inputValue !== '') {
      filtered.push({
        keyword: params.inputValue.toLowerCase(),
        [displayTextKey ?? 'value']: `${strings.shared.ui.add} "${params.inputValue}"`
      });
    }
    return filtered;
  };

  return (
    <AutocompleteSelect
      error={error}
      errorText={errorText}
      filterOptions={filterOptionsWithAddButton}
      freeSolo={allowNewItems}
      getOptionLabel={option => (displayTextKey ? option[displayTextKey] : option)}
      getOptionSelected={(option, value) =>
        displayTextKey ? option[displayTextKey] === value[displayTextKey] : option === value
      }
      inputValue={inputValue}
      multiple
      name={name}
      onInputChange={handleInputChange}
      onSelect={handleSelect}
      onTypeaheadClose={handleClose}
      placeholder={placeholder}
      renderOption={option => <span>{displayTextKey ? option[displayTextKey] : option}</span>}
      selectedValue={selectedValue}
      typeahead
      typeaheadLimit={typeaheadLimit}
      url={endpoint}
      {...rest}
    />
  );
};
