// @flow
import React, { ReactNode, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useDatatable, {
  addRingCentralContactToStore,
  buildRowActionsRendererDefinition,
  extractObjectFromDataTable,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import { rowClickBuilder } from 'hooks/profileActions';
import { useExtendedParams } from 'hooks/useExtendedParams';
import { UserProviderContext } from 'providers/UserProvider';
import { doesUserOwnItem } from 'services/Authorization';
import strings from 'strings';
import type { ProfileDataTableProps } from 'types/app';
import DataTable from 'UI/components/organisms/DataTable';
import { CommunicationActionPreset } from 'UI/constants/defaults';
import { TabKeys } from 'UI/constants/entityTypes';
import { getProfileQuickViewByEntity } from 'UI/constants/LogRocketCustomEvents';
import { getFinalColumns } from 'UI/utils';

import { getEmployeeRole } from '../EmployeesTab/columns';

import ProfileStructure from './components';

type OrderByOptionsTypes = {
  column: string,
  direction: string
};

export type ProfileTableLayoutProps = {
  actionButtonProps: Object,
  actionText: string,
  customRowPermissionResolver?: () => void,
  DataTableProps: ProfileDataTableProps,
  extraRenderers: Array<Object>,
  finalActions: Array<Object>,
  customColumnsExtender?: (action: Function) => Array<Object>,
  hasProfileLoaded: boolean,
  infoLabel: ReactNode,
  initialColumns: Array<Object>,
  isQuickViewMode: boolean,
  onNewItemClick: () => void,
  onQuickViewClick: (Object, string) => void,
  orderByOptions: OrderByOptionsTypes,
  profileModule: string,
  shouldRefresh: boolean,
  tabKey: string,
  paramsBuilder: Array<Object>
};

const {
  inventoryProfiles: { emptyStates }
} = strings;

const tableShadowDeep = 2;

const ProfileTableLayout = ({
  actionButtonProps,
  actionText,
  customColumnsExtender,
  customRowPermissionResolver,
  DataTableProps,
  extraRenderers,
  finalActions,
  hasProfileLoaded,
  infoLabel,
  initialColumns,
  isQuickViewMode,
  onNewItemClick,
  onQuickViewClick,
  orderByOptions,
  paramsBuilder,
  profileModule,
  shouldRefresh,
  tabKey,
  tablePreferencesKey,
  ...rest
}: ProfileTableLayoutProps) => {
  const dispatch = useDispatch();

  const [user] = useContext(UserProviderContext);

  const columnsFromTab = customColumnsExtender ? customColumnsExtender(handleAction) : [];

  const PROFILE_TABLE_COLUMNS = [...getFinalColumns(initialColumns), ...columnsFromTab].filter(
    column => !!column
  );

  const initialPreferences = getColumnPreferences(
    tablePreferencesKey,
    0,
    orderByOptions,
    PROFILE_TABLE_COLUMNS
  );

  const { extendParams } = useExtendedParams(paramsBuilder, null);

  const {
    columnPreferences,
    count,
    data: tabData,
    getData,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleFiltersToggle,
    handleKeywordChange,
    handlePageChange,
    handlePerPageChange,
    listState
  } = useDatatable({
    initialPreferences,
    columnsDefinitions: PROFILE_TABLE_COLUMNS,
    key: tablePreferencesKey,
    paramsExtender: extendParams,
    shouldUseSavedPagination: false,
    ...DataTableProps,
    shouldScrollOnNavigate: true
  });

  const { columns, columnOrder, orderBy, direction } = columnPreferences;
  const { isLoading, showWholeSkeleton, page, perPage, keyword } = listState;

  function handleAction({ rowData, origin }) {
    const role =
      tabKey === TabKeys.Employees ? getEmployeeRole({ columns, rowData }) : profileModule;
    addRingCentralContactToStore({
      rowData,
      columns: PROFILE_TABLE_COLUMNS,
      dispatch,
      role,
      origin
    });
  }
  const handleQuickViewClick = itemData => onQuickViewClick(itemData, profileModule, tabData);

  const quickView = {
    name: 'view',
    callback: handleQuickViewClick,
    enableTracking: true,
    logRocketEvent: getProfileQuickViewByEntity(profileModule)
  };

  const actionsDefinition = onQuickViewClick ? [...finalActions, quickView] : finalActions;

  const actions = actionsDefinition.map(item => {
    const {
      name = '',
      callback = () => {},
      idKey = null,
      pickId = false,
      applyPermissions = false
    } = item;

    const presetNames = CommunicationActionPreset.map(preset => preset.name);
    if (presetNames.includes(name)) return item;

    const enableWhen = (() => {
      if (item.checkIfShouldBeEnabled) {
        return data => item.checkIfShouldBeEnabled(data, tabData);
      }

      if (applyPermissions) {
        if (customRowPermissionResolver) return data => customRowPermissionResolver(data, tabData);
        return userCanManage;
      }

      return () => true;
    })();

    return {
      name,
      onClick: rowClickBuilder({
        callback,
        columns,
        data: tabData,
        getData,
        idKey: idKey || 'id',
        pickId,
        enableTracking: item?.enableTracking,
        logRocketEvent: item?.logRocketEvent
      }),
      enableWhen
    };
  });

  const profileEditionActions = buildRowActionsRendererDefinition({
    actions,
    namespace: profileModule,
    handleAction,
    columns: PROFILE_TABLE_COLUMNS
  });

  const tableActions = actions.length !== 0 ? profileEditionActions : null;

  const finalColumns = getColumnsToRender([...columns, tableActions], extraRenderers);

  const userCanManage = ({ rowData }) => {
    const { id } = extractObjectFromDataTable(initialColumns, ['id'], rowData);
    const selectedItem = tabData.find(item => item.id === id);
    const canManage = doesUserOwnItem(user, selectedItem) && !selectedItem.created_by_system;
    return canManage;
  };

  useEffect(() => {
    shouldRefresh && getData();
  }, [shouldRefresh, getData]);

  const emptyTableStrings = emptyStates[tabKey];
  const emptyStateStringsFallBack = section => emptyTableStrings?.[section] ?? emptyStates.fallBack;

  const emptyTableStringsModes = emptyStateStringsFallBack('profile');

  return (
    <ProfileStructure
      isLoading={isLoading}
      TableRecordsProps={{ count, infoLabel, isQuickViewMode }}
      TableActionsProps={{
        actionButtonProps,
        actionText,
        hasProfileLoaded,
        onNewItemClick
      }}
      profileTable={
        <DataTable
          columnOrder={columnOrder?.length && columnOrder}
          columns={finalColumns}
          count={count}
          data={tabData}
          download={false}
          elevation={tableShadowDeep}
          filter={false}
          isExpandable
          loading={showWholeSkeleton}
          onColumnDisplayClick={handleColumnDisplayChange}
          onColumnOrderChange={handleColumnOrderChange}
          onColumnSortChange={handleColumnSortChange}
          onPageClick={handlePageChange}
          onPerPageClick={handlePerPageChange}
          onSearchTextChange={handleKeywordChange}
          onToggleFilters={handleFiltersToggle}
          page={page}
          print={false}
          refreshing={isLoading}
          responsive="standard"
          rowsPerPage={perPage}
          searchText={keyword}
          selectableRows="none"
          selectToolbarPlacement="none"
          sortOrder={{ name: orderBy, direction }}
          draggableColumns={{
            enabled: true
          }}
          {...emptyTableStringsModes}
          {...rest}
        />
      }
    />
  );
};

ProfileTableLayout.defaultProps = {
  finalActions: [],
  paramsBuilder: [],
  orderByOptions: {
    column: 'id',
    direction: 'desc'
  },
  customRowPermissionResolver: undefined,
  shouldRefresh: false,
  hasProfileLoaded: false,
  profileModule: '',
  onQuickViewClick: null
};

export default ProfileTableLayout;
