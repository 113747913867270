import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';

export const TEXT_COLOR = THEME.typography.overline.color;

export const useStyles = makeStyles(
  theme => ({
    container: {
      margin: theme.spacing(3, 0)
    },
    content: {
      padding: theme.spacing(2),
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      gap: theme.spacing(1.5)
    }
  }),
  { name: 'KeywordTags' }
);
