import { makeStyles } from '@material-ui/core/styles';
import { globalStyles } from 'GlobalStyles';
import {
  mainLayoutPadding,
  sidebarItem,
  sideBarWidth,
  sideBarWidthCollapsed
} from 'UI/constants/dimensions';
import { nestTernary } from 'UI/utils';
import { flexAlignCenterFlexStart, flexAlignCenterJustifyCenter } from 'UI/utils/styles';

const sidebarActionButtonWrapperHeight = 120;

export const useSidebarStyles = makeStyles(theme => ({
  nav: {
    overflow: 'auto',
    overflowX: 'hidden',
    width: '100%',
    height: `calc(100% - ${sidebarActionButtonWrapperHeight}px)`,
    fontWeight: theme.typography.fontWeightLight,
    fontFamily: theme.typography.fontFamilyContent,
    paddingBottom: theme.spacing(2),

    '&::-webkit-scrollbar': {
      width: theme.spacing(1)
    }
  },
  accordionRoot: {
    width: ({ isSidebarOpen }) => (isSidebarOpen ? sideBarWidth : sideBarWidthCollapsed),
    transition: `all ${globalStyles.transition}`,

    '&:before': {
      top: 0
    }
  },
  accordionExpanded: {
    margin: '0 !important'
  },
  sectionWithItems: {
    display: 'flex',
    alignItems: 'center'
  },
  menuBox: {
    ...theme.sidebarItem,
    ...flexAlignCenterFlexStart,
    flexWrap: 'nowrap',
    backgroundColor: theme.sidebarMenu.backgroundColor,
    transition: 'all 0.5s',
    '&:hover': {
      backgroundColor: theme.palette.grey[300]
    }
  },
  lightGray: {
    backgroundColor: theme.palette.grey[200]
  },
  accordionSummaryExpanded: {
    backgroundColor: theme.palette.grey[300],
    fontWeight: 700
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0)
  },
  singleItem: {
    display: 'flex',
    padding: theme.spacing(0, 1.5),
    minHeight: '48px',
    '&:hover': {
      backgroundColor: theme.palette.grey[300]
    },
    paddingLeft: ({ isSidebarOpen, level }) =>
      isSidebarOpen ? nestTernary(level >= 1, 24, 12) : sidebarItem.padding,
    transition: `padding ${globalStyles.transition}, background-color 0.5s`
  },
  itemSelected: {
    color: `${theme.palette.primary.main} !important`,
    fontWeight: 700,
    '& > svg': {
      color: `${theme.palette.primary.main} !important`
    }
  },
  label: {
    marginLeft: theme.spacing(2),
    opacity: ({ isSidebarOpen }) => (isSidebarOpen ? 1 : 0),
    transition: `opacity ${globalStyles.transition}`
  },
  labelBold: {
    fontWeight: theme.typography.fontWeightBold
  },
  popper: {
    display: ({ isSidebarOpen }) => (isSidebarOpen ? 'none' : 'block')
  },
  childrenWrapper: {
    ...flexAlignCenterJustifyCenter,
    padding: theme.spacing(mainLayoutPadding, 0, 4)
  },
  icon: {
    color: theme.palette.grey[900]
  },
  singleItemIcon: {
    color: theme.palette.grey[600]
  },
  iconActive: {
    color: theme.palette.primary.main
  }
}));

export const useStyles = makeStyles(theme => ({
  nav: {
    overflow: 'auto',
    overflowX: 'hidden',
    width: '100%',
    height: `calc(100% - ${sidebarActionButtonWrapperHeight}px)`,
    fontWeight: theme.typography.fontWeightLight,
    fontFamily: theme.typography.fontFamilyContent,
    paddingBottom: theme.spacing(2),

    '&::-webkit-scrollbar': {
      width: theme.spacing(1)
    }
  },
  nested: {
    padding: theme.spacing(4)
  }
}));
