import { makeStyles } from '@material-ui/core';
import { THEME } from 'GlobalStyles';
import { flexAlignCenterJustifyCenter, rippleEffect } from 'UI/utils/styles';

const sharedStyles = {
  ...flexAlignCenterJustifyCenter,
  padding: THEME.spacing(0.5, 1.2),
  borderRadius: '100%',
  width: '44px',
  height: '44px'
};

const { grey } = THEME.palette;

const rippleColor = '#717172';

const phoneItemDefaultIconWidth = 25;

export const useStyles = makeStyles(theme => ({
  actionButton: {
    ...rippleEffect(grey[300], rippleColor),
    ...sharedStyles
  },
  disabledLink: {
    pointerEvents: 'none',
    cursor: 'default'
  },
  mainPhone: {
    display: 'flex',
    alignItems: 'center'
  },
  mainPhoneLabel: {
    paddingRight: theme.spacing(0.5)
  },
  phoneActionRowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& p': {
      margin: 0
    }
  },
  actionsContainer: {
    display: 'flex',
    flex: 0
  },
  defaultIcon: {
    width: phoneItemDefaultIconWidth,
    paddingTop: theme.spacing(0.5),
    ...flexAlignCenterJustifyCenter
  },
  phoneItem: {
    flex: 1
  },
  phoneItemInfo: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary
  }
}));
