import { makeStyles } from '@material-ui/core/styles';
import { commonStyles } from 'UI/constants/styles';
import { colors } from 'UI/res';
import { nestTernary } from 'UI/utils';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

const containerXPadding = 24;

export const useStyles = makeStyles(theme => ({
  drawerContainer: {
    width: '100%',
    height: '100vh',
    top: 0,
    right: 0,
    zIndex: 11,
    boxShadow: '-32px 0px 32px #00000017',
    padding: `0`,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ({ variant }) => (variant === 'blue' ? colors.softBack : colors.sideBar),
    borderLeft: ({ variant }) =>
      `8px solid ${
        variant === 'blue'
          ? colors.success
          : nestTernary(variant === 'white', colors.darkGrey, null)
      }`
  },
  headerContainer: {
    flexBasis: 80,
    boxShadow: '0px 3px 7px 0px #0000000f'
  },
  headerContainerWithoutTitle: {
    flexBasis: 0,
    boxShadow: '0px 3px 7px 0px #0000000f',
    padding: theme.spacing(0, 2)
  },
  headerActions: {
    marginTop: 0
  },
  drawerToolbar: {
    backgroundColor: colors.sideBar,
    display: 'flex',
    height: 88,
    padding: `20px ${containerXPadding}px`,
    boxShadow: commonStyles.drawerFooter.boxShadow
  },
  drawerTopToolbar: {
    position: 'absolute',
    right: 12,
    top: 12
  },
  actionsContainer: {
    height: 40
  },
  primaryButton: {
    marginRight: hasMoreActions => (hasMoreActions ? 6 : 0)
  },
  drawerContent: {
    flexGrow: 1,
    overflowY: 'auto',
    padding: theme.spacing(3)
  },
  wrapper: {
    height: '100%',
    ...flexAlignCenterJustifyCenter
  },
  gridItem: {
    maxWidth: 'unset'
  },
  closeButton: {
    alignSelf: 'end'
  }
}));
