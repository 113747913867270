import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { userHasPermission, userHasRoles } from 'services/Authorization';
import { When } from 'UI/components/atoms/When';
import { getFeatureFlags } from 'UI/utils';

import { NestedMenu } from '../NestedMenu';
import { useSidebarStyles } from '../SidebarV2/styles';

import { getItemStyles } from './MenuItem.utils';

const checkIsVisible = ({ featureFlag, roles, permission }) => {
  const hasFeaturePermission = !featureFlag || getFeatureFlags().includes(featureFlag);
  const hasRolePermission = !roles || userHasRoles(roles);
  const hasModulePermission = !permission || (permission && userHasPermission(permission));
  return hasRolePermission && hasModulePermission && hasFeaturePermission;
};

export const MenuItem = ({ item, level = 0, expandedItems, onExpand, isSidebarOpen }) => {
  const location = useLocation();

  const classes = useSidebarStyles({ isSidebarOpen, level });
  const [anchorEl, setAnchorEl] = useState(null);
  const hasSubItems = item.subItems?.length > 0;
  const isExpanded = expandedItems[level] === item.route;

  const isParentExpanded = level > 0 && expandedItems[level - 1];

  const isItemActive =
    !item.subItems &&
    (item.route === location.pathname ||
      (item.route !== '/' && location.pathname.startsWith(item.route)));

  const isItemOrChildActive = menuItem => {
    if (menuItem.route === location.pathname) return true;
    if (menuItem.subItems) {
      return menuItem.subItems.some(subItem => isItemOrChildActive(subItem));
    }
    return false;
  };

  const handleCloseNestedMenu = () => setAnchorEl(null);

  const handleMenuItemClick = event => {
    if (!isSidebarOpen && level >= 1 && hasSubItems) {
      setAnchorEl(event.currentTarget);
      return;
    }
    onExpand(item.route, level);
  };

  const { iconClasses, labelClasses } = getItemStyles({
    classes,
    hasSubItems,
    isExpanded,
    isItemActive,
    isItemOrChildActive,
    isSidebarOpen,
    item,
    level
  });
  const itemContent = (
    <>
      <SvgIcon component={item.icon} fontSize="small" className={iconClasses} />
      <Typography className={labelClasses} variant="inherit" noWrap>
        {item.title}
      </Typography>
    </>
  );

  if (!checkIsVisible(item) || (!isSidebarOpen && level >= 2)) {
    return null;
  }

  if (!hasSubItems) {
    return (
      <Tooltip classes={{ popper: classes.popper }} title={item.title} placement="right" arrow>
        <Link to={item.route} className="nav-link router-link">
          <div
            className={clsx(
              classes.singleItem,
              classes.menuBox,
              isParentExpanded && classes.lightGray
            )}
          >
            {itemContent}
          </div>
        </Link>
      </Tooltip>
    );
  }

  return (
    <Accordion
      expanded={isExpanded}
      onChange={e => handleMenuItemClick(e)}
      elevation={0}
      classes={{
        root: classes.accordionRoot,
        expanded: classes.accordionExpanded
      }}
    >
      <Tooltip classes={{ popper: classes.popper }} title={item.title} placement="right" arrow>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id={`panel${item.title}-header`}
          classes={{
            root: clsx(classes.singleItem, classes.menuBox, isParentExpanded && classes.lightGray),
            expanded: item.subItems && classes.accordionSummaryExpanded
          }}
        >
          <div className={classes.sectionWithItems}>{itemContent}</div>
        </AccordionSummary>
      </Tooltip>
      <AccordionDetails classes={{ root: classes.accordionDetails }}>
        {item.subItems.map(
          subItem =>
            checkIsVisible(subItem) && (
              <React.Fragment key={subItem.route}>
                <MenuItem
                  item={subItem}
                  level={level + 1}
                  expandedItems={expandedItems}
                  onExpand={onExpand}
                  isSidebarOpen={isSidebarOpen}
                />
                <When condition={!isSidebarOpen && level >= 1}>
                  <NestedMenu
                    items={item.subItems}
                    onClose={handleCloseNestedMenu}
                    anchorEl={anchorEl}
                    title={item.title}
                  />
                </When>
              </React.Fragment>
            )
        )}
      </AccordionDetails>
    </Accordion>
  );
};
