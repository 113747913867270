import { TABLES_KEYS } from 'features/command-center/constants';

export const ACTIVIY_MODE = {
  View: 'view',
  Edit: 'edit'
};

export const ACTIVITY_TYPE_MAP = {
  call: 'Call',
  email: 'Email'
};

export const PAGE_KEY = TABLES_KEYS.feedbackLogSupportActivity;

export const COLUMNS = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'userId',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'createdAt',
    label: 'Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'giverName',
    label: 'Contact',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'giverPositionTitle',
    label: 'Contact Role',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'duration',
    label: 'Duration',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'direction',
    label: 'Direction',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'subject',
    label: 'Subject',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'bodyPreview',
    label: 'Body Preview',
    options: {
      sort: false,
      display: true,
      setCellHeaderProps: () => ({
        style: { minWidth: 400 }
      })
    }
  },
  {
    name: 'notes',
    label: 'Note',
    options: {
      sort: false,
      display: true,
      renderer: 'html',
      setCellHeaderProps: () => ({
        style: { minWidth: 400 }
      })
    }
  },
  {
    name: 'updatedAt',
    label: 'Last Updated on',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'updatedBy.fullName',
    label: 'Updated By',
    options: {
      sort: true,
      display: true
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'createdAt',
  direction: 'desc'
};
