// @flow
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

/**
 * The function sets a schema for a recruiter object with optional properties.
 */
export const setRecruiterSchema = recruiter => ({
  ...recruiter.recruiter,
  coach: recruiter.coach,
  recruiter_to_collaborate_id: recruiter?.recruiter_to_collaborate_id || null,
  type: recruiter.type
});

/**
 * This function adds a "full_name" property to a recruiter object if it exists in the
 * "personalInformation" property.
 */
export const addFullName = recruiter =>
  recruiter && {
    ...recruiter,
    full_name: recruiter?.personalInformation?.full_name
  };

/**
 * The function `isUserOrCoachPresent` checks if the current user is present as a coach in the list of
 * recruiters or if they are related to the coach through team relations.
 * @returns The function `isUserOrCoachPresent` returns a boolean value indicating whether the
 * `currentUser` is present in the `recruiters` array based on matching the `id` of the `currentUser`
 * with the `id` of the `coach` in the `recruiters` array or with the `coachId` in the `teamRelation`
 * of the `currentUser`.
 */
export const isUserOrCoachPresent = (recruiters, currentUser) => {
  return recruiters.some(
    ({ coach }) => coach?.id === currentUser?.id || coach?.id === currentUser?.teamRelation?.coachId
  );
};

/**
 * The function `getMainRecruiter` returns the ID of the main recruiter or the current user's ID if the
 * main recruiter is not found in the given array of recruiters.
 * @returns The function `getMainRecruiter` returns the `recruiter_to_collaborate_id` of the main
 * recruiter if found in the `recruiters` array based on the `currentUser`'s id. If the main recruiter
 * is not found, it returns the `currentUser`'s id.
 */
export const getMainRecruiter = (recruiters, currentUser) => {
  const mainRecuiter = recruiters.find(recruiter => currentUser?.id === recruiter?.id);
  return mainRecuiter?.recruiter_to_collaborate_id || currentUser?.id;
};

/**
 * This function filters and returns a list of available recruiters and collaborators based on the
 * current user and coach relationships.
 * @returns The function `getAvailableRecruitersAndCollabsFromCoach` returns an array of recruiters and
 * collaborators based on the input parameters `recruiters`, `mainSendoutAccountable`, and
 * `currentUser`. The returned array may include recruiters from the coach's team, collaborators of
 * those recruiters, and the main sendout accountable person. The final array is filtered and
 * deduplicated based on certain conditions.
 */
export const getAvailableRecruitersAndCollabsFromCoach = (
  recruiters,
  mainSendoutAccountable,
  currentUser
) => {
  const recruitersOfCoachTeam = recruiters.filter(
    ({ coach }) => coach?.id === currentUser?.id || coach?.id === currentUser?.teamRelation?.coachId
  );

  if (isEmpty(recruitersOfCoachTeam)) {
    const isCollaborator = recruiters.some(recruiter => recruiter?.id === currentUser?.id);
    if (isCollaborator) return recruiters;
    return mainSendoutAccountable ? [mainSendoutAccountable] : recruiters;
  }

  const onlyHasOneRecruiter = recruitersOfCoachTeam.length === 1;
  if (onlyHasOneRecruiter) {
    return mainSendoutAccountable ? recruiters : recruitersOfCoachTeam;
  }

  const collabsOfRecruiters = recruiters.filter(each =>
    recruitersOfCoachTeam.some(recruiter => recruiter.id === each.recruiter_to_collaborate_id)
  );

  const collabsOfCoachTeam = collabsOfRecruiters.filter(
    ({ coach }) => coach?.id === currentUser?.id || coach?.id === currentUser?.teamRelation?.coachId
  );

  if (isEmpty(collabsOfCoachTeam)) {
    return mainSendoutAccountable
      ? uniqBy([...recruitersOfCoachTeam, mainSendoutAccountable], 'id')
      : recruitersOfCoachTeam;
  }
  return uniqBy([...recruitersOfCoachTeam, ...collabsOfCoachTeam], 'id');
};

/**
 * The function `getAvailableRecruitersAndCollabs` filters and combines recruiters based on certain
 * conditions to return a list of available recruiters.
 * @returns The function `getAvailableRecruitersAndCollabs` returns an array of available recruiters
 * and collaborators. If there are no available recruiters, it returns an array containing either
 * `mainSendoutAccountable` or `mainJobOrderRecruiter`. If there are available recruiters, it returns
 * an array containing all available recruiters and collaborators, including `mainSendoutAccountable`
 * if it is provided.
 */
export const getAvailableRecruitersAndCollabs = (
  recruiters,
  mainSendoutAccountable,
  mainJobOrderRecruiter,
  currentUser
) => {
  const mainRecuiterId = getMainRecruiter(recruiters, currentUser);
  const availableRecruiters = recruiters.filter(
    recruiter =>
      recruiter?.id === mainRecuiterId || recruiter?.recruiter_to_collaborate_id === mainRecuiterId
  );
  if (isEmpty(availableRecruiters)) return [mainSendoutAccountable ?? mainJobOrderRecruiter];

  const allRecruiters = mainSendoutAccountable
    ? uniqBy([...availableRecruiters, mainSendoutAccountable], 'id')
    : availableRecruiters;

  return allRecruiters;
};
