import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  menuTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[700],
    padding: theme.spacing(1, 2.5)
  },
  menuList: {
    padding: 0
  },
  listItem: {
    padding: 0,
    display: 'block'
  },
  menuItemWrapper: {
    width: '100%',
    padding: theme.spacing(0.5, 2),
    '&:hover': {
      textDecoration: 'none'
    }
  },
  menuItem: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey[900],
    padding: theme.spacing(0, 0.5),
    display: 'block'
  },
  activeMenuItem: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold
  }
}));
