import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { showAlert } from 'actions/app';
import { Loader } from 'features/command-center/components/shared/Widget/Loader';
import { ENDPOINTS } from 'features/daily-plan/constants';
import strings from 'strings';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';

import { RecruitJobOrder } from '../../forms';

import { FORM_FIELDS_MAP } from './CreationTaskDrawer.constants';

const { form: COPIES } = strings.dailyPlan.drawer;

const getFormToRender = taskTypeId => {
  // TODO: Change to form components when they are created
  const callTaskForm = <h6>Call Task Form</h6>;

  const taskFormMap = {
    1: <RecruitJobOrder />,
    2: <h6>Market Candidate Form</h6>,
    3: callTaskForm,
    4: callTaskForm,
    5: callTaskForm,
    6: <h6>Other Task Form</h6>,
    default: null
  };

  return taskFormMap[taskTypeId] ?? taskFormMap.default;
};

export const CreationTaskDrawer = ({ onClose }) => {
  const [taskType, setTaskType] = useState(null);

  const [uiState, setUIState] = useState({
    isLoading: false,
    isSaving: false
  });

  const dispatch = useDispatch();

  const form = useForm();
  const { handleSubmit } = form;

  const handleSelectChange = (name?, value) => {
    setTaskType(value);
  };

  const onSubmit = async formData => {
    try {
      console.log({ formData }); // TODO: Process formData depending on task type and make the request
    } catch (error) {
      dispatch(
        showAlert({
          severity: 'error',
          title: COPIES.alert.error,
          autoHideDuration: 5000,
          body: COPIES.alert.error
        })
      );
    } finally {
      setUIState(prev => ({
        ...prev,
        isSaving: false
      }));
    }
  };

  return (
    <DrawerContentLayout
      title={COPIES.titles.createTask}
      drawerProps={{
        open: true
      }}
      uiState={{ isSaving: uiState.isSaving, isFormLoading: uiState.isLoading }}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography variant="body1">{COPIES.labels.taskType}</Typography>
      <AutocompleteSelect
        name={FORM_FIELDS_MAP.TaskType.key}
        selectedValue={taskType}
        placeholder={COPIES.placeholders.taskType}
        url={ENDPOINTS.taskTypes}
        onSelect={handleSelectChange}
        renderOption={option => (
          <>
            <Typography variant="body1">{option?.title}</Typography>
            {option?.description && (
              <Typography variant="body2" color="textSecondary">
                {option.description}
              </Typography>
            )}
          </>
        )}
      />

      {uiState.isLoading ? (
        <Loader />
      ) : (
        <FormContext {...form}>{getFormToRender(taskType?.id)}</FormContext>
      )}
    </DrawerContentLayout>
  );
};
