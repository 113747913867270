// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import strings from 'strings';
import type { SearchProjectCollaborationsTypes } from 'types/app';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import FPIcon from 'UI/components/atoms/FPIcon';
import Text from 'UI/components/atoms/Text';
import { When } from 'UI/components/atoms/When';
import ProfileAvatarsList from 'UI/components/organisms/ProfileAvatarsList';
import { SearchProjectPrivacyScopes } from 'UI/constants/defaults';
import { SvgLock, SvgUnlock } from 'UI/res';

import { useTitleDetailsStyles } from '../styles';
import { buildDaysBeforeDeletionCopy, getQueryShortFormatDate } from '../utils';

const privacyIcons = {
  [SearchProjectPrivacyScopes.private.id]: SvgLock,
  [SearchProjectPrivacyScopes.public.id]: SvgUnlock
};

const { actions: actionsCopies } = strings.searchProjects.preview;

type SearchProjectsTitleDetailsPropTypes = {
  avatarsToShow?: number,
  canManageCollaborations?: boolean,
  canViewHistoryLog?: boolean,
  collaborators?: Array<SearchProjectCollaborationsTypes>,
  searchProject: Object,
  onOpenActivityDrawer: () => void,
  onOpenCollaborationsDrawer: () => void
};

const SearchProjectsTitleDetails = ({
  avatarsToShow = null,
  canManageCollaborations = false,
  canViewHistoryLog = false,
  collaborators = null,
  searchProject,
  onOpenActivityDrawer,
  onOpenCollaborationsDrawer
}: SearchProjectsTitleDetailsPropTypes) => {
  const classes = useTitleDetailsStyles();
  const { daysBeforeDeletion } = searchProject;
  const lastSentBulkDate = getQueryShortFormatDate(
    searchProject.lastBulkSentDate,
    format => format.SimpleDateTimeHyphen
  );
  const infoString = searchProject.isArchived
    ? `<span style="color: red">${buildDaysBeforeDeletionCopy(daysBeforeDeletion)}</span>`
    : `Last Bulk Sent On ${lastSentBulkDate}`;

  return (
    <Grid container>
      <When condition={searchProject.privacyScope}>
        <Grid container item alignItems="baseline">
          <FPIcon
            component={privacyIcons[searchProject.privacyScope?.id]}
            size={16}
            family="bold"
            className={classes.privacyIcon}
          />
          <Text text={`${searchProject.privacyScope?.title} /`} />
          <Text
            className={clsx(classes.infoText, searchProject.isArchived && classes.redText)}
            text={infoString}
            variant="body2"
          />
          <When condition={canViewHistoryLog}>
            <FPActionButton
              className={classes.editSearchProjectButton}
              onClick={onOpenActivityDrawer}
              size="small"
              text={actionsCopies.moreOptions.menuItems.historyLog}
              variant="text"
            />
          </When>
        </Grid>
      </When>
      <Grid container item>
        <ProfileAvatarsList
          options={collaborators}
          optionsToShow={avatarsToShow}
          showAddButton={canManageCollaborations && !searchProject.isArchived}
          addButtonProps={{
            tooltipProps: {
              title: actionsCopies.moreOptions.menuItems.manageCollabs,
              placement: 'right'
            },
            onClick: onOpenCollaborationsDrawer
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SearchProjectsTitleDetails;
