import { useCallback, useEffect, useRef, useState } from 'react';

const INITIAL_SCROLL_POSITION = 0;
const SCROLL_UP_LIMIT = 500;
const NEAR_TOP_THRESHOLD = 0.15;
const NEAR_BOTTOM_THRESHOLD = 10;
const SCROLL_DIRECTION = {
  UP: 'up',
  DOWN: 'down'
};

export const useChatScroll = ({ chat, onScrollBottom, onScrollTop, shouldScrollToBottom }) => {
  const [showJumpButton, setShowJumpButton] = useState(false);
  const chatContainerRef = useRef(null);
  const chatEndRef = useRef(null);
  const scrollDirection = useRef(SCROLL_DIRECTION.DOWN);
  const previousScrollHeightRef = useRef(INITIAL_SCROLL_POSITION);
  const previousScrollOffsetRef = useRef(INITIAL_SCROLL_POSITION);

  const scrollToBottom = useCallback(
    (behavior = 'smooth') => {
      if (!chatEndRef.current) return;
      previousScrollHeightRef.current = INITIAL_SCROLL_POSITION;
      previousScrollOffsetRef.current = INITIAL_SCROLL_POSITION;
      chatEndRef.current.scrollIntoView({ behavior });

      onScrollBottom && onScrollBottom();
    },
    [onScrollBottom]
  );

  const handleScroll = useCallback(() => {
    if (!chatContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    const hasScrolledUp = scrollTop + clientHeight < scrollHeight - SCROLL_UP_LIMIT;
    setShowJumpButton(hasScrolledUp);

    const nearTop = scrollTop / scrollHeight <= NEAR_TOP_THRESHOLD;

    if (nearTop) {
      previousScrollOffsetRef.current = scrollHeight - scrollTop;
      previousScrollHeightRef.current = scrollHeight;
      scrollDirection.current = SCROLL_DIRECTION.UP;
      onScrollTop && onScrollTop();
    }

    const atBottom = scrollHeight - (scrollTop + clientHeight) <= NEAR_BOTTOM_THRESHOLD;

    if (atBottom) {
      scrollDirection.current = SCROLL_DIRECTION.DOWN;
      onScrollBottom && onScrollBottom();
    }
  }, [onScrollBottom, onScrollTop]);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;

    if (chatContainer) {
      chatContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    // Effect to maintain scroll position after new messages load
    const chatContainer = chatContainerRef.current;
    if (!chatContainer) return;

    const { scrollHeight } = chatContainer;

    // Adjust scroll position to keep the last view point consistent
    const heightDiff = scrollHeight - previousScrollHeightRef.current;

    if (heightDiff > 0 && scrollDirection.current === SCROLL_DIRECTION.UP) {
      // Restore the previous scroll position using the stored offset
      chatContainer.scrollTop = scrollHeight - previousScrollOffsetRef.current;
    }

    previousScrollHeightRef.current = scrollHeight;
  }, [chat]);

  useEffect(() => {
    if (shouldScrollToBottom) {
      scrollToBottom('instant');
    }
  }, [scrollToBottom, shouldScrollToBottom]);

  return { chatContainerRef, chatEndRef, showJumpButton, scrollToBottom };
};
