import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'UI/res';
import { flexAlignCenterFlexEnd } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  drawerRoot: {
    width: 980,
    overflowY: 'hidden',
    [theme.breakpoints.down(1024)]: {
      width: '80%'
    }
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    height: '100%'
  },
  footerContainer: {
    ...flexAlignCenterFlexEnd,
    backgroundColor: colors.sideBar,
    flexGrow: 1
  },
  drawerContent: {
    padding: 0,
    overflow: 'hidden'
  }
}));
