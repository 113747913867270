import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import { THEME } from 'GlobalStyles';
import strings from 'strings';
import FPIcon from 'UI/components/atoms/FPIcon';
import { When } from 'UI/components/atoms/When';
import FPQuickView from 'UI/components/organisms/inventoryProfiles/FPQuickView';
import { ContactEntityMapping } from 'UI/constants/entityTypes';
import { SvgMessageFilled } from 'UI/res';

import { formatInternationalPhone } from '../../messageChat/chat.utils';

import { PhoneNumbersMenu } from './PhoneNumbersMenu';
import { SearchResultWrapper } from './SearchResult.styled';

const ICON_COLOR = THEME.palette.grey[600];
const DISABLED_COLOR = THEME.palette.grey[300];

export const SearchResult = ({ contact, onNewConversation }) => {
  const roleId = contact.role_id;
  const itemRole = ContactEntityMapping[roleId] || null;
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);
  const toggleQuickViewOpen = () => setIsQuickViewOpen(prev => !prev);

  const handlePhoneClick = ({ contact: receivedContact, selectedPhone }) => {
    const newMessage = {
      id: receivedContact?.id ? `${receivedContact.id}-${selectedPhone.phone}` : null,
      attachmentSize: '0',
      attachments: [],
      countryCode: selectedPhone?.country_code,
      entityId: receivedContact?.id || null,
      entityType: itemRole,
      externalNumber: `${selectedPhone.country_code}${selectedPhone.phone}`,
      fullName: receivedContact.fullName,
      isOutbound: true,
      message: '',
      phone: selectedPhone.phone
    };
    onNewConversation({
      newMessage
    });
  };

  const mainPhone =
    contact.phones?.find(phone => phone.is_default)?.phone || contact.phone || contact.mobile;

  return (
    <SearchResultWrapper>
      <div>
        {contact?.fullName && (
          <Typography variant="body1" component="span">
            {contact.fullName}&nbsp;
          </Typography>
        )}

        <Typography variant="body2" component="span">
          {formatInternationalPhone(mainPhone) || strings.messageCenter.error.invalidPhone}
        </Typography>
        {contact?.industryAndRole && (
          <Typography variant="body2">{contact.industryAndRole}</Typography>
        )}
        {contact?.email && <Typography variant="body2">{contact.email}</Typography>}
      </div>

      <div>
        {contact?.createButton ? (
          <Tooltip title={strings.messageCenter.newConversation.sendMessage}>
            <IconButton
              onClick={() =>
                handlePhoneClick({
                  contact,
                  selectedPhone: { phone: contact.phone, country_code: '' }
                })
              }
              disabled={!contact.phone}
            >
              <FPIcon
                component={SvgMessageFilled}
                color={contact.phone ? ICON_COLOR : DISABLED_COLOR}
              />
            </IconButton>
          </Tooltip>
        ) : (
          <>
            <PhoneNumbersMenu contact={contact} onPhoneClick={handlePhoneClick} />
            <Tooltip title={strings.inventoryProfiles.quickView.viewProfile}>
              <IconButton onClick={toggleQuickViewOpen}>
                <FPIcon component={Visibility} />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>

      <When condition={contact.id && itemRole && isQuickViewOpen}>
        <FPQuickView
          id={contact.id}
          drawerProps={{
            open: isQuickViewOpen
          }}
          entityType={itemRole}
          onClose={toggleQuickViewOpen}
        />
      </When>
    </SearchResultWrapper>
  );
};
