import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import strings from 'strings';
import CustomDatePicker from 'UI/components/atoms/CustomDatePicker';
import { REQUIRED_VALIDATION } from 'UI/utils';

import { DEFAULT_TIME_PICKER_PROPS, FORM_FIELDS_MAP } from './DateTimePickers.constants';

const STRINGS = strings.dailyPlan.drawer.form;

export const DateTimePickers = () => {
  const { errors, register, unregister, watch, setValue } = useFormContext();

  const formValues = watch();

  const handleDateChange = (name, value) => {
    setValue(name, value ? moment(value) : null, { shouldValidate: true });
  };

  useEffect(() => {
    register(FORM_FIELDS_MAP.Date.key, { ...REQUIRED_VALIDATION });
    register(FORM_FIELDS_MAP.Time.key);

    return () => {
      unregister(FORM_FIELDS_MAP.Date.key);
      unregister(FORM_FIELDS_MAP.Time.key);
    };
  }, [register, unregister]);

  return (
    <>
      <Grid item xs={6}>
        <CustomDatePicker
          disablePast
          isClearable
          error={!!errors[FORM_FIELDS_MAP.Date.key]}
          helperText={errors[FORM_FIELDS_MAP.Date.key]?.message}
          label={STRINGS.placeholders.setDate}
          name={FORM_FIELDS_MAP.Date.key}
          value={formValues[FORM_FIELDS_MAP.Date.key] || null}
          onDateChange={handleDateChange}
          placeholder={STRINGS.placeholders.setDate}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomDatePicker
          disablePast
          isClearable
          error={!!errors[FORM_FIELDS_MAP.Time.key]}
          helperText={errors[FORM_FIELDS_MAP.Time.key]?.message}
          label={STRINGS.placeholders.setTime}
          name={FORM_FIELDS_MAP.Time.key}
          value={formValues[FORM_FIELDS_MAP.Time.key] || null}
          onDateChange={handleDateChange}
          variant="keyboardTime"
          withTime
          {...DEFAULT_TIME_PICKER_PROPS}
        />
      </Grid>
    </>
  );
};
