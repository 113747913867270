import Grid from '@material-ui/core/Grid';
import { makeStyles, styled } from '@material-ui/core/styles';

export const Container = styled(Grid)({
  width: '100%'
});

export const TableContainer = styled(Grid)({
  height: 'fit-content'
});

export const useStyles = makeStyles(theme => ({
  drawerLarge: {
    width: 1100,
    overflowY: 'hidden',
    [theme.breakpoints.down(1024)]: {
      width: '80%'
    }
  },
  drawerContent: {
    padding: 0,
    flexGrow: 1,
    overflowY: 'auto'
  },
  topContainer: {
    flex: 0
  },
  buttonContainer: {
    padding: theme.spacing(3)
  }
}));

export const TopContentWrapper = styled(Grid)({
  flex: 0
});

export const ButtonContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(3)
}));
