// @flow
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { useProfile } from 'hooks/profileActions';
import { useSearchProjectInProfiles } from 'hooks/searchProject';
import { EntityRoutes } from 'routes/constants';
import { ContactIncludes } from 'services/includes';
import strings from 'strings';
import { When } from 'UI/components/atoms/When';
import {
  getOptOutDate,
  handleEntityEditionCompleted
} from 'UI/components/organisms/inventoryProfiles/utils';
import LinkCompanyDialog from 'UI/components/organisms/LinkCompanyDialog';
import NameEdit from 'UI/components/organisms/NameEdit';
import InventoryProfileLayout from 'UI/components/templates/InventoryProfileLayout';
import { DateFormats } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import {
  ContactEntity,
  EntityType,
  SearchProjectEntities,
  TabKeys
} from 'UI/constants/entityTypes';
import { getId, localTimeFormatter } from 'UI/utils';

import { useContactProfileFeatureFlags } from '../ContactProfile.hooks';

type ContactProfileProps = {
  id: number,
  enableUrlNavigation: boolean
};

const createTabsProps = ({ fullName, results, searchProjectTabPropsDefinition }) => [
  {
    id: TabKeys.ActivityLogs,
    tabProps: {
      itemName: fullName,
      profileId: results?.id,
      profileModule: EntityType.Contact
    }
  },
  searchProjectTabPropsDefinition,
  {
    id: TabKeys.Notes,
    tabProps: {
      itemName: fullName,
      profileId: results?.id,
      profileModule: EntityType.Contact
    }
  }
];

const ContactProfileContent = ({
  id,
  layoutClassName,
  enableUrlNavigation,
  onEditionCompleted
}: ContactProfileProps) => {
  const history = useHistory();
  const endpoint = `${Endpoints.Names}/${id}`;

  const { isChangeLogsTabEnabled, isEnabledAddAsACandidate } = useContactProfileFeatureFlags();

  const [tabKeys, setTabKeys] = useState({
    [TabKeys.SearchProjects]: 0
  });

  const triggerTabRefresh = tabName => setTabKeys(prev => ({ ...prev, [tabName]: getId() }));

  const {
    handleEditClick,
    handleEditClosed,
    handleProfileStateUpdate,
    isLoading,
    refreshData,
    results,
    state,
    status,
    uiState,
    handleProfileUiState
  } = useProfile({
    entity: EntityType.Contact,
    profileId: id,
    apiVersion: 2,
    includes: ContactIncludes,
    shouldLoadTitle: enableUrlNavigation
  });

  const fullName = results?.personalInformation?.full_name;
  const lastActivity = results?.last_activity_date;

  const formattedDate = localTimeFormatter(lastActivity, DateFormats.SimpleDateTime);

  const handleCompanyUpdate = async ({ current_company, company_id }) => {
    handleProfileStateUpdate(prev => ({
      ...prev,
      current_company,
      company_id
    }));
    await refreshData();
  };

  const handleEditCompleted = async () => {
    handleProfileUiState({ statesToHandle: { isEditOpen: false } });
    await refreshData(false);
  };

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   S E A R C H   P R O J E C T S   S E T T I N G S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const { SearchProjectForms, SearchProjectsMenuItems, searchProjectTabPropsDefinition } =
    useSearchProjectInProfiles({
      endpoint,
      entity: SearchProjectEntities.Contact,
      profile: {
        id: results?.id,
        name: fullName
      },
      tabKey: tabKeys.searchProjects,
      triggerTabRefresh,
      shouldRedirectToSearchProjectPreview: enableUrlNavigation
    });

  const tabsProps = createTabsProps({
    fullName,
    endpoint,
    results,
    searchProjectTabPropsDefinition
  });

  const headerProps = {
    sectionName: 'Contacts',
    title: fullName
  };

  const handleCreateCandidateClick = () =>
    history.push(`${EntityRoutes.CandidateCreate}?contactId=${id}`);

  const customMenuItems = [
    ...SearchProjectsMenuItems,
    {
      title: strings.inventoryProfiles.common.candidateAddition,
      action: () => handleCreateCandidateClick(),
      visible: isEnabledAddAsACandidate
    }
  ];

  const { optOutDate } = getOptOutDate(results);

  const summaryLabels = [
    { title: strings.inventoryProfiles.common.optOut.creation, content: optOutDate }
  ];

  return (
    <>
      <InventoryProfileLayout
        activityLabel={formattedDate}
        addItemLabel="Add a Collaborator"
        contentPageLayoutProps={{ className: layoutClassName }}
        enableUrlNavigation={enableUrlNavigation}
        entityType={ContactEntity}
        headerCardProps={headerProps}
        inventoryType={results?.hiringAuthorityStatus}
        isChangeLogsTabEnabled={isChangeLogsTabEnabled}
        results={results}
        summaryLabels={summaryLabels}
        profileMenuProps={{
          customMenuItems,
          onEditClick: handleEditClick
        }}
        ProfileTabsProps={{
          endpoint,
          profileTabProps: tabsProps
        }}
        ProfileUiStateManagerProps={{
          entity: ContactEntity,
          entityRoute: EntityRoutes.Contacts,
          onRefreshProfileClick: refreshData,
          responseStatusCode: state.responseStatusCode,
          status
        }}
      />
      <When condition={!isLoading}>
        <>
          {results?.shouldAskRecruiterAddEmployer && (
            <LinkCompanyDialog
              type={EntityType.ContactType.Contact}
              entity={results}
              onSetProfile={handleCompanyUpdate}
            />
          )}
          <Drawer open={uiState.isEditOpen} onClose={handleEditClosed}>
            <div role="presentation">
              <NameEdit
                name={results}
                onEditCompleted={handleEntityEditionCompleted({
                  handleEditCompleted,
                  enableUrlNavigation,
                  onEditionCompleted
                })}
                onEditClosed={handleEditClosed}
              />
            </div>
          </Drawer>
          <SearchProjectForms />
        </>
      </When>
    </>
  );
};

export default ContactProfileContent;
