import { DateFormats } from 'UI/constants/defaults';

export const FORM_FIELDS_MAP = {
  Date: {
    key: 'date'
  },
  Time: {
    key: 'time'
  }
};

export const DEFAULT_TIME_PICKER_PROPS = {
  isClearable: false,
  withTime: true,
  autoOk: true,
  timeFormat: DateFormats.SimpleTime,
  mask: '__:__ _M',
  placeholder: 'HH:MM AM',
  variant: 'keyboardTime'
};
