import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { MenuItem } from '../MenuItem';

import SidebarMenu from './config';
import { useSidebarStyles } from './styles';
import { findParentRoutes } from './utils';

export const SidebarV2 = ({ children }) => {
  const { isSidebarOpen } = useSelector(({ app }) => app.ui);
  const classes = useSidebarStyles({ isSidebarOpen });
  const location = useLocation();
  const [expandedItems, setExpandedItems] = useState({});

  useEffect(() => {
    const parentRoutes = findParentRoutes(SidebarMenu, location.pathname);
    setExpandedItems(parentRoutes);
  }, [location.pathname]);

  const handleExpand = (route, level) => {
    setExpandedItems(prev => {
      if (prev[level] === route) {
        const { [level]: _, ...rest } = prev;
        return rest;
      }
      return {
        ...prev,
        [level]: route
      };
    });
  };

  return (
    <>
      <div className={classes.childrenWrapper}>{children}</div>
      <nav className={classes.nav}>
        {SidebarMenu.map(item => (
          <MenuItem
            key={item.route}
            item={item}
            expandedItems={expandedItems}
            onExpand={handleExpand}
            isSidebarOpen={isSidebarOpen}
          />
        ))}
      </nav>
    </>
  );
};
