const isRouteMatch = (subItem, currentPath, level) =>
  subItem.route === currentPath ||
  (subItem.route !== '/' && currentPath.startsWith(subItem.route)) ||
  (subItem.subItems && Object.keys(findParentRoutes([subItem], currentPath, level + 1)).length > 0);

export const findParentRoutes = (items, currentPath, level = 0, result = {}) => {
  return items.reduce((acc, item) => {
    if (item.subItems && item.subItems.some(subItem => isRouteMatch(subItem, currentPath, level))) {
      return {
        ...acc,
        [level]: item.route,
        ...findParentRoutes(item.subItems, currentPath, level + 1)
      };
    }
    return acc;
  }, result);
};
