import { makeStyles } from '@material-ui/core/styles';
import { container as containerShadow } from 'UI/constants/dimensions';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

const inventorySectionHeader = 110;
const minLayoutHeight = 700;
const LAYOUT_BOTTOM_MARGINS = {
  lessThanMediumScreen: 130,
  greaterThanMediumScreen: 200
};

export const useStyles = makeStyles(theme => ({
  bulkLayout: ({ shouldWrapIntoFiltersLayout }) => ({
    ...containerShadow,
    overflow: 'hidden',
    backgroundColor: theme.palette.customColors.white,
    height: shouldWrapIntoFiltersLayout ? '100%' : `calc(100% - ${theme.spacing(5)}px)`,
    marginTop: theme.spacing(shouldWrapIntoFiltersLayout ? 0 : 4),
    '& > *': {
      height: '100%'
    }
  }),
  controlsColumn: {
    height: 'auto !important',
    backgroundColor: theme.palette.customColors.sideBar,
    borderRight: `1px solid ${theme.palette.customColors.borderColor}`
  },
  buttonContainer: {
    ...flexAlignCenterJustifyCenter,
    height: inventorySectionHeader
  },
  menuItemsList: {
    padding: 0
  },
  menuListItem: {
    padding: theme.spacing(1.5, 3),
    '&:hover': {
      backgroundColor: theme.palette.customColors.lightButtonHover
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.customColors.linkWater,
      '&:hover': {
        backgroundColor: theme.palette.customColors.lightButtonHover
      }
    }
  },
  menuListItemIcon: {
    flexGrow: 1,
    justifyContent: 'center'
  },
  actionButton: ({ responsiveGridWidth }) => ({
    width: responsiveGridWidth ? 80 : 150,
    minWidth: responsiveGridWidth && 'unset',
    margin: theme.spacing(0, 2),
    '& .MuiButton-startIcon, & .MuiButton-endIcon': {
      margin: responsiveGridWidth && 0
    },
    '& .MuiButton-endIcon': {
      display: responsiveGridWidth && 'none'
    },
    '& .MuiButton-label': {
      alignItems: responsiveGridWidth && 'center',
      justifyContent: responsiveGridWidth && 'center'
    }
  }),
  responsivePaper: {
    transform: 'translate(58px, 5px) !important'
  },
  pageLayout: {
    overflow: 'hidden',
    minHeight: minLayoutHeight,
    height: '100%',
    '& > *:last-child': {
      overflowY: 'hidden',
      [theme.breakpoints.up(1020)]: {
        height: `calc(100% - ${LAYOUT_BOTTOM_MARGINS.lessThanMediumScreen}px)`
      },
      [theme.breakpoints.down(1020)]: {
        height: `calc(100% - ${LAYOUT_BOTTOM_MARGINS.greaterThanMediumScreen}px)`
      }
    }
  }
}));
