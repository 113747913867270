import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import TreeView from '@material-ui/lab/TreeView';
import { Loader } from 'features/command-center/components/shared/Widget/Loader';
import { useFetchWithStatusV2 } from 'hooks/fetchWithStatus';
import { EntityRoutes } from 'routes/constants';
import { FPCard, FPCardContent } from 'UI/components/atoms/FPCard';
import FPLink from 'UI/components/atoms/FPLink';
import { Endpoints } from 'UI/constants/endpoints';
import { UIStatus } from 'UI/constants/status';

import {
  CloseSquare,
  MinusSquare,
  PlusSquare,
  StyledTreeItem,
  useStyles
} from './AssociatedCompaniesTree.styles';
import { countElements, getAllNodeIds } from './AssociatedCompaniesTree.utils';

export const CompanyRow = memo(
  ({ id, name, type, location, childrenCount, isCurrentCompany = false }) => {
    const classes = useStyles({ isCurrentCompany, name });

    const companyName = isCurrentCompany ? (
      <Typography className={classes.companyName}>{name}</Typography>
    ) : (
      <FPLink
        itemId={id}
        onClick={e => e.stopPropagation()}
        className={classes.companyName}
        linkProps={{
          type: 'local',
          url: EntityRoutes.CompanyProfile
        }}
      >
        {name}
      </FPLink>
    );

    return (
      <div className={classes.treeItemContainer}>
        {companyName}

        {(location?.city || location?.state) && (
          <Typography component="span" variant="body1" className={classes.locationText}>
            - {location?.city}, {location?.state}
          </Typography>
        )}
        {type && (
          <Chip
            size="small"
            className={`chip-company-type chip-company-type-${type.id}`}
            aria-label="status chip"
            label={type.title}
          />
        )}
        <Typography className={classes.countLabel}>({childrenCount})</Typography>
      </div>
    );
  }
);

export const TreeNodes = memo(
  ({ nodes, currentCompanyId }) => {
    const itemsBelow = useMemo(() => countElements(nodes), [nodes]);

    return (
      <StyledTreeItem
        key={nodes.id}
        nodeId={`${nodes.id}`}
        label={
          <CompanyRow
            id={nodes.id}
            name={nodes.name}
            type={nodes.type}
            location={nodes.location}
            childrenCount={itemsBelow}
            isCurrentCompany={nodes.id === Number(currentCompanyId)}
          />
        }
      >
        {nodes.children.map(node => (
          <TreeNodes key={node.id} nodes={node} currentCompanyId={currentCompanyId} />
        ))}
      </StyledTreeItem>
    );
  },
  (prevProps, nextProps) =>
    prevProps.nodes === nextProps.nodes && prevProps.currentCompanyId === nextProps.currentCompanyId
);

const AssociatedCompaniesTree = ({ companyId, shouldRefresh }) => {
  const classes = useStyles();

  const { Status, state, refreshData } = useFetchWithStatusV2({
    endpoint: `${Endpoints.Companies}/${companyId}/${Endpoints.AssociatedCompaniesTree}`,
    apiVersion: 2
  });

  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);

  const handleToggle = useCallback((event, nodeIds) => {
    setExpanded(nodeIds);
  }, []);

  const handleSelect = useCallback((event, nodeIds) => {
    setSelected(nodeIds);
  }, []);

  useEffect(() => {
    if (state.status === UIStatus.Success && state.results.length > 0) {
      const allNodeIds = getAllNodeIds(state.results[0]);
      setExpanded(allNodeIds);
    }
  }, [state.results, state.status]);

  useEffect(() => {
    shouldRefresh && refreshData();
  }, [refreshData, shouldRefresh]);

  const renderTreeView = useCallback(
    data => (
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}
        disableSelection
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        aria-label="Company Hierarchy"
      >
        <TreeNodes nodes={data[0]} currentCompanyId={companyId} />
      </TreeView>
    ),
    [classes.root, expanded, selected, handleToggle, handleSelect, companyId]
  );

  return (
    <FPCard className={classes.card}>
      <FPCardContent
        headerProps={{
          title: 'Company Hierarchy'
        }}
        className={classes.treeContent}
      >
        <Status
          loading={<Loader alignItems="start" />}
          success={renderTreeView}
          error={error => <Typography color="error">Error: {error.message}</Typography>}
        />
      </FPCardContent>
    </FPCard>
  );
};

export default memo(AssociatedCompaniesTree);
