// @flow
import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Popper from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import { useChatContext } from 'features/message-center/components/messageChat/ChatDrawerProvider';
import { extractObjectFromDataTable, getDefaultPhoneNumber } from 'hooks/datatable';
import { Permissions, userHasPermission } from 'services/Authorization';
import strings from 'strings';
import type { RowActionsProps } from 'types/app';
import ConditionalActionWrapper from 'UI/components/atoms/ConditionalWrapper';
import FPButtonMenu from 'UI/components/molecules/FPButtonMenu';
import InfoCard from 'UI/components/molecules/InfoCard';
import { PHONE_TYPE_MAP } from 'UI/components/organisms/PhoneNumbersForm/utils';
import { RowActions as RowActionsNames } from 'UI/constants/defaults';
import { ContactRole, ContactRoleStrings, EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import {
  SvgCheck,
  SvgDeleteFilled,
  SvgDownloadFilled,
  SvgEdit,
  SvgEmailFilled,
  SvgInfoCardFilled,
  SvgMessageFilled,
  SvgPhoneFilled,
  SvgSendFilled,
  SvgSubtractCircle,
  SvgViewFilled
} from 'UI/res/icons/filled';
import { formatPhoneNumber, hasFeatureFlag } from 'UI/utils';

import { useStyles } from './styles';

const { menuItems: menuItemsCopies } = strings.shared.rowActions;

const COMMUNICATION_ACTION_TITLES = {
  Call: 'Call',
  Text: 'Text',
  Email: 'Email'
};

const landlineId = PHONE_TYPE_MAP[1].id;

const hasSMSEnabled = rowData => {
  if (!rowData) return true;
  const defaultPhone = getDefaultPhoneNumber(rowData);
  return defaultPhone && defaultPhone?.type_id !== landlineId;
};

const getRecipientByTableData = ({ data, columns }) => {
  const { rowData } = data;
  const item = extractObjectFromDataTable(
    columns,
    [
      'id',
      'phone',
      'full_name',
      'name',
      'personalInformation',
      'role_id',
      'roleId',
      'item_search_project_title'
    ],
    rowData
  );
  const id = item?.id ?? item?.full_name?.id ?? item?.name?.id;
  const name =
    item?.full_name?.full_name ??
    item?.name?.name ??
    item?.personalInformation?.personalInformation?.full_name;
  const roleId =
    item?.role_id ??
    item?.roleId ??
    ContactRole[item?.item_search_project_title?.replace(/\s+/g, '')];
  const role = ContactRoleStrings[roleId] ? ContactRoleStrings[roleId].replace(/\s+/g, '') : null;
  const { country_code: countryCode, phone: defaultPhone } = getDefaultPhoneNumber(rowData);
  return {
    phone: defaultPhone ?? item?.phone,
    countryCode,
    name,
    entityId: id,
    entityType: EntityType[role]
  };
};

const RowActions = ({
  actions,
  contactValues,
  data,
  namespace,
  onPhoneClick,
  columns,
  ...rest
}: RowActionsProps) => {
  const classes = useStyles();
  const { openDrawer } = useChatContext();
  const canUseTextel = userHasPermission(Permissions.Textel.CanUseModule);

  const openChatDrawer = () => {
    if (!data) return;

    const recipient = columns
      ? getRecipientByTableData({ data, columns })
      : data?.personalInformation?.contact?.phone;

    const phoneWithMask = recipient?.phone ? formatPhoneNumber(recipient?.phone) : null;

    recipient &&
      recipient.entityId &&
      openDrawer({
        recipient: {
          ...recipient,
          phone: phoneWithMask,
          entityType: recipient?.entityType ?? namespace
        }
      });
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const iconSize = '20px';
  const maxActions = 4;

  const getAction = action => {
    if (action.value === 'contactData') return { ...action, value: contactValues };

    return { ...action, value: contactValues[action.value] };
  };

  const finalActions = actions.map(action => (!action.value ? action : getAction(action)));

  const initiallyVisibleActions =
    finalActions.length > maxActions ? finalActions.slice(0, maxActions - 1) : finalActions;
  const restActions = finalActions.length > maxActions ? finalActions.slice(maxActions - 1) : null;

  const renderCommunicationAction = ({
    title,
    hrefPrefix,
    Icon,
    contactData,
    isContactToTrack,
    darkMode = false
  }) => {
    const isTextAction = title === COMMUNICATION_ACTION_TITLES.Text;

    const entityData = data?.rowData ?? null;
    const isActionEnabled =
      !hasFeatureFlag(FeatureFlags.MultiplePhonesWithCommunicationActions) || !isTextAction
        ? !!contactData
        : hasSMSEnabled(entityData);

    const iconClassLightMode = clsx(classes.icon, !isActionEnabled && classes.disabledIcon);
    const iconClassDarkMode = isActionEnabled ? classes.iconDarkMode : classes.icon;

    const IconClasses = darkMode ? iconClassDarkMode : iconClassLightMode;

    return (
      <div key={title} className={clsx(!isActionEnabled && classes.disabledLink)} {...rest}>
        <Tooltip title={title} placement="bottom" arrow>
          {canUseTextel && isTextAction ? (
            <IconButton
              className={darkMode ? classes.darkAction : classes.action}
              onClick={openChatDrawer}
            >
              <Icon className={IconClasses} />
            </IconButton>
          ) : (
            <Link
              href={`${hrefPrefix}:${contactData}`}
              className={darkMode ? classes.darkAction : classes.action}
              onClick={isContactToTrack && onPhoneClick ? onPhoneClick : null}
            >
              <Icon className={IconClasses} />
            </Link>
          )}
        </Tooltip>
      </div>
    );
  };

  const renderAction = ({ title, Icon, onClick, enableWhen, children }) => {
    const isEnabled = (enableWhen ? !!enableWhen(data) : true) && onClick;
    return (
      <div key={title} className={clsx(!isEnabled && classes.disabledLink)} {...rest}>
        <ConditionalActionWrapper
          condition={isEnabled}
          wrapper={(c = children) => (
            <Tooltip title={title} placement="bottom" arrow>
              {c}
            </Tooltip>
          )}
        >
          <IconButton onClick={buildMenuOptionEventHandler(onClick)} disabled={!isEnabled}>
            <Icon fill="currentColor" width={iconSize} />
          </IconButton>
        </ConditionalActionWrapper>
      </div>
    );
  };

  const renderInfoCardAction = ({ title, Icon, enableWhen, contactData }) => {
    const isEnabled = enableWhen ? !!enableWhen(contactData) : true;

    const {
      email,
      full_name: fullName,
      initials,
      phone,
      ext,
      coach,
      location,
      timezone
    } = contactData;
    const details = [
      { text: location && timezone ? `${location} (${timezone})` : null, cropped: false },
      { text: coach ? `Team ${coach}` : null },
      { text: email || '' },
      {
        text: ext ? `Ext. ${ext}` : null
      }
    ];
    const actionsProps = {
      namespace: 'Home',
      actions: [
        {
          name: RowActionsNames.Call,
          value: 'phone',
          darkMode: true
        },
        {
          name: RowActionsNames.Text,
          value: 'phone',
          darkMode: true,
          visible: !canUseTextel
        },
        {
          name: RowActionsNames.Email,
          value: 'email',
          darkMode: true
        }
      ],
      contactValues: {
        phone,
        email
      }
    };

    const handleClick = event => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);

    return (
      <div key={title} className={clsx(!isEnabled && classes.disabledLink)} {...rest}>
        <div>
          <Tooltip title={title} placement="bottom" arrow disableHoverListener={!isEnabled}>
            <IconButton onClick={handleClick} disabled={!isEnabled}>
              <Icon fill="currentColor" width={iconSize} />
            </IconButton>
          </Tooltip>
          <Popper
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            PaperProps={{ elevation: 0 }}
          >
            <InfoCard
              ActionsComponent={RowActions}
              customClasses={classes.infoCard}
              email={email}
              infoCardProps={{ details, actionsProps }}
              initials={initials}
              title={fullName ?? contactData?.fullName}
            />
          </Popper>
        </div>
      </div>
    );
  };

  const ActionRenderers = {
    call: ({ value: contactData, darkMode = false }) => {
      const callConfig = {
        title: 'Call',
        hrefPrefix: 'tel',
        Icon: SvgPhoneFilled,
        contactData,
        isContactToTrack: true,
        darkMode
      };

      return renderCommunicationAction(callConfig);
    },
    delete: ({ onClick, enableWhen }) => {
      const deleteConfig = {
        title: 'Remove',
        Icon: SvgDeleteFilled,
        onClick,
        enableWhen
      };

      return renderAction(deleteConfig);
    },
    download: ({ onClick, enableWhen }) => {
      const downloadConfig = {
        title: 'Download',
        Icon: SvgDownloadFilled,
        onClick,
        enableWhen
      };

      return renderAction(downloadConfig);
    },
    edit: ({ onClick, enableWhen }) => {
      const editConfig = {
        title: 'Edit',
        Icon: SvgEdit,
        onClick,
        enableWhen
      };

      return renderAction(editConfig);
    },
    add: ({ onClick, enableWhen, title, Icon }) => {
      const addConfig = {
        title: title ?? 'Add',
        Icon: Icon ?? AddIcon,
        onClick,
        enableWhen
      };

      return renderAction(addConfig);
    },
    setMainCompany: ({ onClick, enableWhen }) => {
      const setMainConfig = {
        title: 'Set as main',
        Icon: SvgCheck,
        onClick,
        enableWhen
      };
      return renderAction(setMainConfig);
    },
    email: ({ value: contactData, darkMode = false }) => {
      const emailConfig = {
        title: 'Email',
        hrefPrefix: 'mailto',
        Icon: SvgEmailFilled,
        contactData,
        isContactToTrack: false,
        darkMode
      };

      return renderCommunicationAction(emailConfig);
    },
    remove: ({ onClick, enableWhen, ...restActionProps }) => {
      const viewConfig = {
        title: 'Remove',
        Icon: SvgSubtractCircle,
        onClick,
        enableWhen,
        ...restActionProps
      };

      return renderAction(viewConfig);
    },
    sendReminder: ({ onClick, enableWhen }) => {
      const viewConfig = {
        title: 'Send Reminder',
        Icon: SvgSendFilled,
        onClick,
        enableWhen
      };

      return renderAction(viewConfig);
    },
    text: ({ value: contactData, darkMode = false, visible = true }) => {
      const textConfig = {
        title: 'Text',
        hrefPrefix: 'sms',
        Icon: SvgMessageFilled,
        contactData,
        isContactToTrack: true,
        darkMode,
        visible
      };

      return visible ? renderCommunicationAction(textConfig) : null;
    },
    view: ({ onClick, enableWhen, title }) => {
      const viewConfig = {
        title: title ?? 'View details',
        Icon: SvgViewFilled,
        onClick,
        enableWhen
      };

      return renderAction(viewConfig);
    },
    viewContact: ({ value: contactData, enableWhen }) => {
      const viewContactConfig = {
        contactData,
        enableWhen,
        Icon: SvgInfoCardFilled,
        onClick: 'infoCard',
        title: 'View Contact Information'
      };

      return renderInfoCardAction(viewContactConfig);
    }
  };

  const getRenderer = action => ActionRenderers[action?.name]?.(action) || null;

  const buildMenuOptionEventHandler = (callback: Function) => (event: any) => {
    event && event.stopPropagation();
    callback && callback(data);
  };

  const MenuItemRenderers = {
    call: ({ value }) => ({
      title: 'Call',
      visible: true,
      wrapper: 'a',
      isDisabled: !value,
      contactData: {
        href: `tel:${value}`,
        onPhoneClick
      }
    }),
    delete: ({ onClick }, isEnabled) => ({
      isDisabled: !onClick || !isEnabled,
      title: 'Remove',
      action: buildMenuOptionEventHandler(onClick),
      visible: true
    }),
    download: ({ onClick }, isEnabled) => ({
      isDisabled: !onClick || !isEnabled,
      title: 'Download',
      action: buildMenuOptionEventHandler(onClick),
      visible: true
    }),
    edit: ({ onClick }, isEnabled) => ({
      isDisabled: !onClick || !isEnabled,
      title: 'Edit',
      action: buildMenuOptionEventHandler(onClick),
      visible: true
    }),
    email: ({ value }) => ({
      title: 'Email',
      visible: true,
      wrapper: 'a',
      isDisabled: !value,
      contactData: {
        href: `mailto:${value}`
      }
    }),
    remove: (button, isEnabled) => {
      return {
        isDisabled: !button.onClick || !isEnabled,
        title: menuItemsCopies.remove.title,
        action: buildMenuOptionEventHandler(button.onClick),
        visible: true
      };
    },
    sendReminder: ({ onClick }, isEnabled) => ({
      isDisabled: !onClick || !isEnabled,
      title: 'Send Reminder',
      action: buildMenuOptionEventHandler(onClick),
      visible: true
    }),
    setMainCompany: ({ onClick }, isEnabled) => ({
      isDisabled: !onClick || !isEnabled,
      title: 'Set as main',
      action: buildMenuOptionEventHandler(onClick),
      visible: true
    }),
    text: ({ value }) => ({
      title: 'Text',
      visible: true,
      wrapper: 'a',
      isDisabled: !value,
      contactData: {
        href: `sms:${value}`,
        onPhoneClick
      }
    }),
    view: ({ onClick }, isEnabled) => ({
      isDisabled: !onClick || !isEnabled,
      title: 'View Details',
      action: buildMenuOptionEventHandler(onClick),
      visible: true
    })
  };

  const getMenuItemRenderer = action =>
    MenuItemRenderers[action?.name]?.(
      action,
      action?.enableWhen ? !!action.enableWhen(data) : true
    ) || null;

  const menuItems = restActions && restActions.map(restAction => getMenuItemRenderer(restAction));

  const handleOnClick = e => {
    e.stopPropagation();
    window.phoneActionName = namespace;
  };

  return (
    <div
      className={classes.container}
      onClick={handleOnClick}
      onKeyPress={handleOnClick}
      role="link"
      tabIndex={0}
      data-testid="row-actions"
    >
      {initiallyVisibleActions.map(action => getRenderer(action))}
      {restActions && <FPButtonMenu isIconButton menuItems={menuItems} />}
    </div>
  );
};

RowActions.defaultProps = {
  actions: [],
  contactValues: {},
  data: null,
  namespace: '',
  onPhoneClick: null
};

export default RowActions;
