import React, { useState } from 'react';
import { CreationTaskDrawer } from 'features/daily-plan';
import usePageTitle from 'hooks/usePageTitle';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { When } from 'UI/components/atoms/When';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { backNavigateListConfig } from 'UI/constants/config';
import { PageTitles } from 'UI/constants/defaults';
import { SvgAdd } from 'UI/res';

import { ActionContent } from './DailyPlan.styles';

const COPIES = strings.dailyPlan;
const ICON_BUTTON_SIZE = 16;

export const DailyPlan = () => {
  usePageTitle({ title: PageTitles.DailyPlan });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  return (
    <FiltersLayout title={COPIES.title} titleLabelProps={backNavigateListConfig}>
      <ActionContent>
        <FPActionButton
          icon={SvgAdd}
          text={COPIES.actionButton}
          onClick={handleOpenDrawer}
          iconProps={{ family: 'mini', size: ICON_BUTTON_SIZE }}
        />
      </ActionContent>

      <h5>Content</h5>
      <When condition={isDrawerOpen}>
        <CreationTaskDrawer onClose={handleCloseDrawer} />
      </When>
    </FiltersLayout>
  );
};
