// @flow

import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
import AllInboxRoundedIcon from '@material-ui/icons/AllInboxRounded';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionIcon from '@material-ui/icons/Description';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import DvrRounded from '@material-ui/icons/DvrRounded';
import EmojiEventsRounded from '@material-ui/icons/EmojiEventsRounded';
import EventAvailableRounded from '@material-ui/icons/EventAvailableRounded';
import FindInPageRoundedIcon from '@material-ui/icons/FindInPageRounded';
import ForumRoundedIcon from '@material-ui/icons/ForumRounded';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import GroupWorkRoundedIcon from '@material-ui/icons/GroupWorkRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import InsertDriveFileRoundedIcon from '@material-ui/icons/InsertDriveFileRounded';
import LanguageRoundedIcon from '@material-ui/icons/LanguageRounded';
import LocalAtmRoundedIcon from '@material-ui/icons/LocalAtmRounded';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import PageviewRoundedIcon from '@material-ui/icons/PageviewRounded';
import PersonPinRoundedIcon from '@material-ui/icons/PersonPinRounded';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import RoomRoundedIcon from '@material-ui/icons/RoomRounded';
import SmsIcon from '@material-ui/icons/Sms';
import StoreMallDirectoryRoundedIcon from '@material-ui/icons/StoreMallDirectoryRounded';
import SupervisedUserCircleRoundedIcon from '@material-ui/icons/SupervisedUserCircleRounded';
import Timeline from '@material-ui/icons/Timeline';
import WorkRoundedIcon from '@material-ui/icons/WorkRounded';
import { EntityRoutes } from 'routes/constants';
import { Permissions } from 'services/Authorization';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { Roles } from 'UI/constants/roles';

/**
 * @typedef subItems
 * @property {String} title
 * @property {String} route
 * @property {String} [featureFlag]
 * @property {IconItem} icon
 */
/**
 * {subItems[]}
 */

const subItems = {
  commandCenter: [
    {
      title: 'Daily Tracker',
      route: EntityRoutes.Home,
      icon: HomeRoundedIcon
    },
    {
      title: 'Tools',
      route: '/tools',
      icon: PlaylistAddCheck,
      subItems: [
        {
          title: 'Tools Usage',
          route: EntityRoutes.DashboardToolsUsageReport,
          featureFlag: FeatureFlags.DashboardToolsUsageReport
        },
        {
          title: 'Calls & Texts',
          route: EntityRoutes.DashboardPhone
        },
        {
          title: 'Bulks',
          route: EntityRoutes.DashboardBulkEmail
        }
      ]
    },
    {
      title: 'Productivity',
      route: '/productivity',
      icon: DvrRounded,
      subItems: [
        {
          title: 'Sendouts',
          route: EntityRoutes.DashboardSendout,
          featureFlag: FeatureFlags.DashboardSendouts
        },
        {
          title: 'Inventory',
          route: EntityRoutes.DashboardOverview
        },
        {
          title: 'Fee Agreements',
          route: EntityRoutes.DashboardFeeAgreements,
          featureFlag: FeatureFlags.DashboardFeeAgreements
        }
      ]
    },
    {
      title: 'Sendouts Leaderboard',
      route: EntityRoutes.DashboardSendoutLeaders,
      icon: EmojiEventsRounded,
      featureFlag: FeatureFlags.DashboardSendoutLeaders
    },
    {
      title: 'Overview',
      route: EntityRoutes.CommandCenterOverview,
      icon: DashboardIcon,
      permission: Permissions.CommandCenter.Overview.CanUseModule
    },
    {
      title: 'Overall Performance',
      route: EntityRoutes.CommandCenterOverallPerformance,
      icon: Timeline,
      permission: Permissions.CommandCenter.Activity.CanUseModule
    },
    {
      title: 'Daily Performance',
      route: EntityRoutes.CommandCenterDailyPerformance,
      icon: InsertDriveFileRoundedIcon,
      permission: Permissions.CommandCenter.DailyPerformance.CanUseModule
    },
    {
      title: 'Dominate Sheet',
      route: EntityRoutes.CommandCenterDominateSheet,
      icon: DescriptionIcon,
      permission: Permissions.CommandCenter.DominateSheet.CanUseModule
    }
  ],
  directory: [
    {
      title: 'Contacts',
      route: EntityRoutes.Contacts,
      icon: AccountCircleRoundedIcon
    },
    {
      title: 'Companies',
      route: EntityRoutes.Companies,
      icon: BusinessRoundedIcon
    }
  ],
  inventory: [
    {
      title: 'Candidates',
      route: EntityRoutes.Candidates,
      icon: PersonPinRoundedIcon
    },
    {
      title: 'Job Orders',
      route: EntityRoutes.JobOrders,
      icon: WorkRoundedIcon
    },
    {
      title: 'Hot Sheet',
      route: EntityRoutes.Sendouts,
      permission: Permissions.Sendouts.CanUseModule,
      icon: ForumRoundedIcon
    },
    {
      title: 'Placements',
      route: EntityRoutes.Placements,
      permission: Permissions.Placements.CanUseModule,
      icon: MonetizationOnRoundedIcon
    },
    {
      title: 'AR & Collections',
      route: EntityRoutes.Collections,
      roles: [Roles.Coach, Roles.Finance, Roles.Leadership, Roles.AssistantRegionalDirector],
      featureFlag: FeatureFlags.PlacementCollections,
      icon: LocalAtmRoundedIcon
    },
    {
      title: 'Channel Partner HS',
      route: EntityRoutes.SendoutsFromChannelPartner,
      permission: Permissions.Sendouts.CanUseModule,
      featureFlag: FeatureFlags.SendoutsChannelPartner,
      icon: AccountTreeRoundedIcon
    }
  ],
  pil: [
    {
      title: 'Map',
      route: EntityRoutes.Map,
      icon: RoomRoundedIcon
    },
    {
      title: 'Industries',
      route: EntityRoutes.Industries,
      featureFlag: FeatureFlags.IndustriesViewer,
      icon: StoreMallDirectoryRoundedIcon
    },
    {
      title: 'Teams',
      route: EntityRoutes.Teams,
      featureFlag: FeatureFlags.IndustriesViewer,
      icon: SupervisedUserCircleRoundedIcon
    }
  ]
};

/**
 * @typedef SidebarItem
 * @property {String} title
 * @property {String} route
 * @property {String} [permission]
 * @property {String} [featureFlag]
 * @property {Array} subItems
 * @property {Number} position
 * @property {IconItem} icon
 */

/**
 * {SidebarItem[]}
 */
const sideBarMenu = [
  {
    title: 'Command Center',
    route: EntityRoutes.CommandCenter,
    position: 1,
    icon: LanguageRoundedIcon,
    subItems: subItems.commandCenter
  },
  {
    title: 'Directory',
    route: 'directory',
    position: 2,
    icon: AccountBoxRoundedIcon,
    subItems: subItems.directory
  },
  {
    title: 'Inventory',
    route: 'inventory',
    position: 3,
    icon: GroupRoundedIcon,
    subItems: subItems.inventory
  },
  {
    title: 'Daily Plan',
    route: EntityRoutes.DailyPlan,
    position: 4,
    icon: EventAvailableRounded,
    featureFlag: FeatureFlags.DailyPlan
  },
  {
    title: 'Search Projects',
    route: EntityRoutes.SearchProject,
    position: 5,
    icon: FindInPageRoundedIcon
  },
  {
    title: 'Text Messages',
    route: EntityRoutes.MessageCenter,
    featureFlag: FeatureFlags.MessageCenter,
    permission: Permissions.Textel.CanUseModule,
    position: 6,
    icon: SmsIcon
  },
  {
    title: 'Bulks',
    route: EntityRoutes.BulkEmail,
    position: 7,
    icon: AllInboxRoundedIcon
  },
  {
    title: 'Fee Agreements',
    route: EntityRoutes.FeeAgreements,
    position: 8,
    icon: DescriptionRoundedIcon
  },
  {
    title: 'PIL',
    route: 'pil',
    position: 9,
    icon: PageviewRoundedIcon,
    subItems: subItems.pil
  },
  {
    title: 'Roster',
    route: EntityRoutes.Roster,
    position: 10,
    icon: GroupWorkRoundedIcon
  }
];

export default sideBarMenu.sort((a, b) => a.position - b.position);
