import strings from 'strings';

export const SEARCH_TYPES = {
  contact: 'contact',
  message: 'message'
};

export const DEFAULT_MESSAGE_STATUS = 'defaultStatus';

export const MESSAGE_STATUS = {
  sending: 1,
  delivered: 2,
  failed: 3,
  optedOut: 4,
  partialFailure: 5,
  partialOptOut: 6,
  [DEFAULT_MESSAGE_STATUS]: DEFAULT_MESSAGE_STATUS
};
export const MESSAGE_FAILURE_STATUSES = [
  MESSAGE_STATUS.failed,
  MESSAGE_STATUS.optedOut,
  MESSAGE_STATUS.partialFailure,
  MESSAGE_STATUS.partialOptOut
];
export const MESSAGE_STATUS_COPIES = {
  [MESSAGE_STATUS.sending]: strings.messageCenter.error.default,
  [MESSAGE_STATUS.delivered]: strings.messageCenter.error.default,
  [MESSAGE_STATUS.failed]: strings.messageCenter.error.default,
  [MESSAGE_STATUS.optedOut]: strings.messageCenter.error.optedOut,
  [MESSAGE_STATUS.partialFailure]: strings.messageCenter.error.default,
  [MESSAGE_STATUS.partialOptOut]: strings.messageCenter.error.default,
  [DEFAULT_MESSAGE_STATUS]: ''
};

export const DEFAULT_COUNTRY_CODE = '+1';
