import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import { useStyles } from './NestedMenu.styles';

export const NestedMenu = ({ items, title, onClose, anchorEl }) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <Menu
      id={`sidebar-menu-${title}`}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
      MenuListProps={{
        component: 'div'
      }}
    >
      <Typography className={classes.menuTitle}>{title}</Typography>
      <List component="ul" className={classes.menuList}>
        {items.map(item => (
          <ListItem key={item.title} component="li" disableGutters className={classes.listItem}>
            <MenuItem
              component={Link}
              to={item.route}
              onClick={onClose}
              className={classes.menuItemWrapper}
            >
              <Typography
                component="span"
                className={clsx(
                  classes.menuItem,
                  item.route === location.pathname && classes.activeMenuItem
                )}
              >
                {item.title}
              </Typography>
            </MenuItem>
          </ListItem>
        ))}
      </List>
    </Menu>
  );
};
